exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._30rfT {\n  background-color: #F6F4F0;\n  text-align: center;\n  line-height: 50px;\n  color: #a0a0a0;\n}\n._2MbGa {\n  padding: 10px 20px 20px;\n}\n._2MbGa .KS4Cy {\n  text-align: center;\n}\n._2MbGa .KS4Cy ._3PDDz,\n._2MbGa .KS4Cy ._3GbcN {\n  display: inline-block;\n  padding: 10px;\n  vertical-align: top;\n}\n._2MbGa .KS4Cy ._3PDDz._3PDDz,\n._2MbGa .KS4Cy ._3GbcN._3PDDz {\n  text-align: right;\n}\n._2MbGa .KS4Cy ._3PDDz._3GbcN,\n._2MbGa .KS4Cy ._3GbcN._3GbcN {\n  text-align: left;\n}\n._2MbGa ._1emJ_ {\n  border-bottom: 1px solid #CBCBCB;\n  padding: 5px 10px;\n}\n._2MbGa ._1emJ_ label {\n  font-size: 1rem !important;\n  width: 120px !important;\n}\n._2MbGa ._1emJ_ ._2qy3g {\n  margin-left: 135px;\n}\n._2MbGa ._1emJ_ ._2qy3g input {\n  border-bottom: 0;\n}\n._2MbGa ._1emJ_ ._2qy3g input::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n._2MbGa ._1emJ_ ._2qy3g input::-moz-placeholder {\n  text-transform: capitalize;\n}\n._2MbGa ._1emJ_ ._2qy3g input:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._2MbGa ._1emJ_ ._2qy3g input::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._2MbGa ._1emJ_ ._2qy3g input::placeholder {\n  text-transform: capitalize;\n}\n._2MbGa ._3csuU {\n  margin-top: 20px;\n  text-align: right;\n}\n", ""]);

// Exports
exports.locals = {
	"desc": "_30rfT",
	"container": "_2MbGa",
	"currentUser": "KS4Cy",
	"label": "_3PDDz",
	"value": "_3GbcN",
	"formGroup": "_1emJ_",
	"formControls": "_2qy3g",
	"button": "_3csuU"
};