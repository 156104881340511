import React, { PureComponent, Fragment } from 'react';
import ReactModal from 'react-modal';
import ScrollLock from 'react-scrolllock';
import PropTypes from 'prop-types';
import styles from './Modal.less';

class Modal extends PureComponent {
	constructor() {
		super();
		this.isIOS = this.isIOS.bind(this);
	}

	isIOS() {
		return !!navigator.platform.match(/iPhone|iPod|iPad/);
	}

	render() {
		const { isOpen, onToggle, title, style } = this.props;
		return (
			<Fragment>
				<ScrollLock isActive={ isOpen && !this.isIOS() } />
				<ReactModal
					isOpen={ isOpen }
					onRequestClose={ onToggle }
					ariaHideApp={ false }
					style={ style }
				>
					{
						title && (
							<h4 className={ styles.modalTitle }>
								{ title }
							</h4>
						)
					}

					{ this.props.children }
				</ReactModal>
			</Fragment>
		);
	}
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	title: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.node.isRequired,
};

export default Modal;