import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactLoadingOverlay from 'react-loading-overlay';
import loc from 'Components/languages';
import Spinner from 'Components/spinner';

class LoadingOverlay extends PureComponent {
	constructor() {
		super();
		this.styles = {
			overlay: (base) => ({
				...base,
				background: 'rgba(255, 255, 255, 0.7)',
			}),
			// spinner: (base) => ({
			// 	...base,
			// 	'& svg circle': {
			// 		stroke: 'rgba(0, 0, 0, 1)'
			// 	}
			// }),
			content: (base) => ({
				...base,
				color: '#000',
			}),
		};
	}

	render() {
		const { active, children, className } = this.props;
		let text = this.props.text || `${loc.loading}...`;
		return (
			<ReactLoadingOverlay
				active={ active }
				spinner={ <Spinner /> }
				text={ text }
				styles={ this.styles }
				className={ classnames(className) }
			>
				{ children }
			</ReactLoadingOverlay>
		);
	}
}

LoadingOverlay.propTypes = {
	active: PropTypes.bool.isRequired,
	children: PropTypes.node,
	text: PropTypes.string,
	className: PropTypes.string,
};

LoadingOverlay.defaultProps = {
	className: '',
};

export default LoadingOverlay;