import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Top from 'Components/top';
import styles from './index.less';

class Error404 extends PureComponent {
	render() {
		const { showSidebar } = this.props;

		const body = (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<Top name="UnknownError" />
					<div className="uk-padding-small">
						<p className="uk-text-lead uk-margin-remove-bottom">Ooops...looks like an error occurred!</p>
						<p className="uk-text-large">
							<small>Error Code</small>
							<br />
							404
						</p>
						
						<Link to="/app/home" className="uk-button uk-button-primary">
							Back to Home
						</Link>
					</div>
				</div>
			</Fragment>
		);

		return showSidebar ? (
			<div className="uk-grid" data-uk-height-viewport>
				<nav id="menuBar" className={ classnames('uk-width-small', styles.menuBar) }>
					<img src="/assets/images/logo.png" className={ styles.logo } />
				</nav>
				<main id="workspace" className="uk-position-relative uk-width-expand uk-padding-small">
					{ body }
				
					<div className={ classnames('uk-position-absolute', 'uk-position-bottom-left', styles.copyright) }>
						&copy; 2019 PUYI GROUP LIMITED (HONG KONG)
					</div>
				</main>
			</div>
		) : body;
	}
}

Error404.propTypes = {
	showSidebar: PropTypes.bool,
};

Error404.defaultProps = {
	showSidebar: false,
};

export default Error404;
