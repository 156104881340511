// The localization data and functions are configured here

import LocalizedStrings from 'react-localization';

const loc = new LocalizedStrings({
	'en': {
		'login': 'login',
		'username': 'Username',
		'password': 'Password',
		'warehouseCode': 'Warehouse Code',
		'loginFailTitle': 'login fail',
		'loginFailDesc': 'incorrect username / password',
		'mustGrantLocationAccessPermission': 'You must grant permission to access location in order to login.',
		'posFullMenu': 'POS Full Menu',
		'currentShop': 'Current Shop',
		'salesOrder': 'Sales Order',
		'serviceMemo': 'Service Memo',
		'stockCheck': 'Stock Check',
		'orderSearch': 'Order Search',
		'report': 'Report',
		'menu': 'Menu',
		'shopOrder': 'Shop Order',
		'stockTransferIn': 'Stock Transfer In',
		'stockTransferOut': 'Stock Transfer Out',
		'language': 'Language',
		'cashMovement': 'Cash Movement',
		'logout': 'Logout',
		'userManual': 'User Manual',
		'selectLanguage': 'Select your language preference',
		'changePassword': 'Input current and new password to update',
		'currentPassword': 'Current',
		'newPassword': 'New',
		'confirmPassword': 'Confirm',
		'currentUser': 'Current User',
		'update': 'UPDATE',
		'validating': 'Validating',
		'loading': 'Loading',
		'confirming': 'Confirming',
		'user': 'User',
		'changeWarehouse': 'Change Warehouse',
		'scanBarcode': 'Scan Barcode',
		'editMember': 'Edit Member',
		'remarkTemplate': 'Remark Template',
		'salesman': 'Salesman',
		'cart': 'Cart',
		'payment': 'Payment',
		'preview': 'Preview',
		'print': 'Print',
		'messages': 'Messages',
		'salesmanDetails': 'Salesman Details',
		'mainSalesman': 'Main Salesman',
		'supportSalesman': 'Support Salesman',
		'optometristDetails': 'Optometrist Details',
		'optometrist': 'Optometrist',
		'memberDetails': 'Member Details',
		'memberNumber': 'Member Code',
		'memberName': 'Member Name',
		'mobilePhone': 'Mobile Phone',
		'memberTier': 'Member Tier',
		'continue': 'Continue',
		'clickToSearch': 'Click to Search',
		'allShop': 'All Shop',
		'salesmanSearch': 'Salesman Search',
		'optometristSearch': 'Optometrist Search',
		'search': 'Search',
		'memberSearch': 'Member Search',
		'productCart': 'Product Cart',
		'qOrder': 'Q Order',
		'rOrder': 'R Order',
		'itemName': 'item name',
		'itemCode': 'item code',
		'lotNo': 'lot no',
		'brandName': 'brand name',
		'unitPrice': 'unit price',
		'qty': 'Qty',
		'amount': 'amount',
		'noLotNo': 'No Lot No.',
		'remark': 'Remark',
		'editRemark': 'Tap to Edit Remark',
		'edit': 'Edit',
		'template': 'Template',
		'retailAmount': 'Retail Amount',
		'totalQty': 'Total Qty',
		'totalDiscount': 'Total Discount',
		'netAmount': 'Net Amount',
		'discount': 'Discount',
		'cancel': 'Cancel',
		'apply': 'Apply',
		'depositTotal': 'Deposit Amount',
		'methods': 'Methods',
		'paymentMethods': 'Payment Method',
		'orderType': 'Order Type',
		'paymentAmount': 'Payment Amount',
		'change': 'Change',
		'totalResult': 'Total Result',
		'placeOrder': 'Place Order',
		'orderedOn': 'Ordered On',
		'orderNo': 'Order#',
		'customerSignature': 'Customer Signature',
		'clear': 'Clear',
		'createNewOrder': 'New Order',
		'printInvoice': 'Print Invoice',
		'updateResults': 'Update Results',
		'insertLotNo': 'Insert Lot No.',
		'tapToEditRemark': 'Tap to Edit Remark',
		'salesmanPopupDesc': 'Select one of the salesmen below by clicking on the item',
		'memberPopupDesc': 'Select one of the members below by clicking on the item',
		'changeWarehousePopupDesc': 'Select one of the warehouses below by clicking on the item',
		'changeWarehousesPopupDesc': 'Select warehouses below by clicking on the items',
		'remarkPopupDesc': 'Type in remarks or select from templates',
		'noResult': 'No result found. Please apply/update filters.',
		'promotions': 'promotions',
		'approvalCode': 'Approval Code',
		'supervisorApproval': 'Supervisor Approval',
		'confirm': 'Confirm',
		'holdOrder': 'Hold Order',
		'notification': 'Notification',
		'exclusive': 'Exclusive',
		'multiple': 'Multiple',
		'other': 'Other',
		'coupon': 'Coupon',
		'pleaseInputDepositAmount': 'Please input deposit amount.',
		'orderPreview': 'Order Preview',
		'balanceDue': 'Balance Due',
		'download': 'Download',
		'commitQty': 'Committed Quantity',
		'salesmanSearchHelpText': 'Search by User ID or name',
		'memberSearchHelpText': 'Search by member code / name / phone / mobile',
		'depositRefund': 'Deposit Refund',
		'selectAll': 'Select All',
		'deselectAll': 'Deselect All',
		'addServiceItems': 'Add Service Items',
		'insertPromotionCode': 'Insert Promotion Code',
		'missingCriteria': 'Missing Criteria',
		'fulfilledCriteria': 'Fulfilled Criteria',
		'pattern': 'Pattern: ',
		'rangeFrom': 'Range from ',
		'rangeTo': 'to',
		'currencyCode': 'Currency Code',
		'exchangeRate': 'Exchange Rate',
		'amount2': 'Amount',
		'addPaymentMethod': 'Add Payment Method',
		'optom': 'Optom',
		'optomRecord': 'Optometric Record',
		'optomNew': 'N',
		'optomFPD': 'FPD',
		'optomNPD': 'NPD',
		'optomSPH': 'SPH',
		'optomCYL': 'CYL',
		'optomAXIS': 'AXIS',
		'optomPRISM': 'PRISM',
		'optomBASE': 'BASE',
		'optomADD': 'ADD',
		'optomVA': 'Adj.',
		'orderNumber': 'Order Number',
		'expiryDate': 'Expiry Date',
		'recentOrderMembers': 'Recent in Last 30 Days',
		'newMembers': 'New in Last 30 Days',
		'favoriteMembers': 'Favorite',
		'serviceItems': 'Service Items',
		'favoriteItems': 'Popular Items',
		'addToFavorite': 'Add to Popular Items',
		'removeFromFavorite': 'Remove from Popular Items',
		'addedToFavorite': 'Added to Popular Items',
		'removedFromFavorite': 'Removed from Popular Items',
		'addedBy': 'Added by',
		'at': 'at',
		'additionalOptions': 'Additional Options',
		'pleaseSelect': 'Please Select',
		'pleaseSelectQROrder': 'Please select Q or R order',
		'with': 'w/',
		'consumptionTax': 'GST',
		'advancedSearch': 'Advanced Search',
		'typeAndSearch': 'Type and Search',
		'reset': 'Reset',
		'sorting': 'Condition',
		'ascending': 'Ascending',
		'descending': 'Descending',
		'stockAmount': 'Stock Amount',
		'appliedFilters': 'Applied Filters',
		'filtering': 'Advanced Filter',
		'category': 'Category',
		'frames': 'Frames',
		'framesGJ': 'Frames GJ',
		'sunglasses': 'Sunglasses',
		'sunglassesGJ': 'Sunglasses GJ',
		'lenses': 'Lenses',
		'contactLenses': 'Contact Lenses',
		'gifts': 'Gifts',
		'services': 'Services',
		'collection': 'Collection',
		'series': 'Series',
		'inStock': 'In Stock',
		'retailPrice': 'Retail Price',
		'season': 'Season',
		'supplierSeason': 'Supplier Season',
		'frameShape': 'Frame Shape',
		'age': 'Age',
		'gender': 'Gender',
		'origin': 'Origin',
		'rimType': 'RIM Type',
		'rimColour': 'RIM Colour',
		'lensColour': 'Lens Colour',
		'rimMtl': 'RIM MTL',
		'rimMtlDetails': 'RIM MTL Details',
		'armMtl': 'ARM MTL',
		'armMtlDetails': 'ARM MTL Details',
		'replacement': 'Replacement',
		'duration': 'Duration',
		'bc': 'B.C.',
		'sph': 'SPH',
		'cyl': 'CYL',
		'axis': 'AXIS',
		'add': 'ADD',
		'sortBy': 'Sort By',
		'orderBy': 'Order By',
		'transactionDate': 'Transaction Date',
		'event': 'Event',
		'jewellery': 'Jewellery',
		'solution': 'Solution',
		'appliedPmsFilters': 'Applied Filters',
		'appliedOpmsFilters': 'Applied Opms Filters',
		'filteringPms': 'Filtering',
		'filteringOpms': 'Opms Filtering',
		'return': 'Return',
		'returnPopupDesc': 'Select the item(s) below to be return',
		'viewOrder': 'View Order',
		'redeemOrder': 'Redeem Order',
		'voidOrder': 'Void Order',
		'retrieveHoldOrder': 'Retrieve Order',
		'searchHelpText': 'Search by item name, code, lot no. or description',
		'retailPriceFrom': 'Retail Price (From)',
		'retailPriceTo': 'Retail Price (To)',
		'specificShops': 'Specific Shops',
		'serviceCart': 'Service Cart',
		'salesQ': 'Sales Q',
		'salesR': 'Sales R',
		'onHold': 'On Hold',
		'serviceQ': 'Service Q',
		'serviceR': 'Service R',
		'transfer': 'Transfer',
		'complete': 'Complete',
		'incomplete': 'Incomplete',
		'redeemed': 'Redeemed',
		'ready': 'Ready',
		'all': 'All',
		'orderStatus': 'Order Status',
		'onHoldSalesOrder': 'Sales Hold',
		'onHoldServiceMemo': 'Service Hold',
		'orderSearchHelpText': 'Search by transaction no., deposit no., salesman code, member code, member name or member mobile',
		'stockTransferOrderSearchHelpText': 'Search by order no. or remark',
		'selectReport': 'Select Report',
		'generate': 'Generate',
		'shop': 'Shop',
		'dateFrom': 'Date From',
		'dateTo': 'Date To',
		'reportFilters': 'Report Filters',
		'depositNo': 'Deposit No',
		'depositDate': 'Deposit Date',
		'reportSalesman': 'Salesman',
		'reportBrand': 'Brand',
		'reportItem': 'Item',
		'depositStatus': 'Deposit Status',
		'reportCategory': 'Category',
		'docType': 'Doc Type',
		'select': 'Select',
		'trxDate': 'Trx. Date ',
		'invoiceNo': 'Invoice No.',
		'modelNo': 'Model No.',
		'salesman1': 'Salesman 1',
		'salesman2': 'Salesman 2',
		'salesman3': 'Salesman 3',
		'caseNo': 'Case No.',
		'stockNo': 'Stock No.',
		'stdRetailPrice': 'Std retail price',
		'soldPrice': 'Sold price',
		'fromLoc': 'From Loc.',
		'toLoc': 'To Loc.',
		'transferDate': 'Transfer Date',
		'transerNo': 'Transfer No.',
		'orderingNo': 'Ordering No.',
		'export': 'Export',
		'endOfReport': 'End Of Report',
		'exclude': 'Excl.',
		'stockTransfer': 'Stock Transfer',
		'transferIn': 'Transfer In',
		'transferOut': 'Transfer Out',
		'sourceDestination': 'Source',
		'destination': 'Destination',
		'createTransferOut': 'Create',
		'checkInOrder': 'Check In Order',
		'createDate': 'Create Date',
		'transferringItem': 'Transferring Item',
		'scanToCheckInItems': 'Scan to Check-In Items',
		'scanToCheckOutItems': 'Scan to Check-Out Items',
		'resetOrder': 'Reset Order',
		'removeItem': 'Remove Item',
		'checkIn': 'Check In',
		'checkOut': 'Check Out',
		'destinationLocation': 'Destination Location',
		'address': 'Address',
		'phone': 'Phone',
		'itemList': 'Item List',
		'updateQuantity': 'Update Quantity',
		'addItemsByBrand': 'Add Items by Brands',
		'selectBrand': 'Select Brand',
		'confirmToCheckOut': 'Confirm to check out?',
		'selectRecord': 'Select Record',
		'recordInfo': 'Record Info',
		'cashier': 'Cashier',
		'currency': 'Currency',
		'date': 'Date',
		'description': 'Description',
		'tapToAddDescription': 'Tap to add a description',
		'paymentInfo': 'Payment Info',
		'changeAmount': 'Change Amount',
		'opening': 'Opening',
		'netPayAmount': 'Net Pay Amount',
		'cashFlowEntry': 'Cash Flow Entry',
		'addItem': 'Add',
		'balance': 'Balance',
		'accumulate': 'Accumulate',
		'save': 'Save',
		'time': 'Time',
		'debit': 'Debit (DR)',
		'credit': 'Credit (CR)',
		'cashEntryDesc': 'Select or input report criteria',
		'assigned': 'Assigned',
		'priceChange': 'Price Change',
		'addRemarkTemplate': 'Add Remark Template',
		'editRemarkTemplate': 'Edit Remark Template',
		'remarkTemplateSearchHelpText': 'Search by content',
		'createdAt': 'Created At',
		'updatedOn': 'Updated At',
		'submit': 'Submit',
		'remarkContent': 'Remark Content',
		'status': 'Status',
		'Enabled': 'Enabled',
		'Disabled': 'Disabled',
		'enabled': 'enabled',
		'disabled': 'disabled',
		'createdBy': 'Created By',
		'updatedBy': 'Updated By',
		'MSGRemarkTemplateCreated': 'The remark template is created successfully.',
		'MSGRemarkTemplateUpdated': 'The remark template is updated successfully.',
		'MSGRemarkTemplateNotCreated': 'Error occured! The remark template is NOT created.',
		'MSGRemarkTemplateNotUpdated': 'Error occured! The remark template is NOT updated.',
		'DP1': 'DP1',
		'SA1': 'SA1',
		'SA3': 'SA3',
		'MSGItemFound': 'Below item is found',
		'MSGItemNotFound': 'Item is not found',
		'MSGTransferInItemReceived': 'Items are received',
		'MSGTransferOutCreated': 'Transfer Order Success',
		'MSGTransferOutCreatedFail': 'Transfer Order Fail',
		'MSGTransferInConfirm': 'Confirm To Receive',
		'MSGTransferOutConfirm': 'Confirm To Transfer',
		'MSGApprovalFail': 'Approval Fail',
		'MSGFieldsRequired': 'Please fill in all required fields (*).',
		'MSGConfirmCashSuccess': 'Cash Movement Success',
		'MSGConfirmCashFail': 'Cash Movement Fail',
		'OK': 'OK',
		'MSGNetworkNotConnected': 'Network is not connected',
		'MSGCouponInvalid': 'Coupon is not valid',
		'MSGCouponExpired': 'Coupon is expired',
		'MSGCouponNotForCurrentShop': 'Coupon is not for current shop',
		'MSGShopOrderCreated': 'Shop order is created',
		'MSGOrderSaved': 'Order is saved',
		'MSGPasswordExpired': 'Your password is expired, please update the password',
		'MSGPasswordDifferent': 'Your new password and confirm password are different',
		'MSGPasswordUpdated': 'Password is updated',
		'MSGCreditInput': 'Credit already input',
		'MSGDebitInput': 'Debit already input',
		'MSGLogoutConfirm': 'Are you sure to log out?',
		'MSGIncorrectApprovalCode': 'Incorrect Approval Code',
		'MSGIncorrectApprovalCodeOrPassword': 'Incorrect Approval Code or Password',
		'errorOccurred': 'Error Occurred',
		'VALID0001': 'Request is invalid, please input information correctly',
		'UnknownError': 'Unknown Error',
		'ITEMFILTER0001': 'Item Filter not found',
		'CF0001': 'Cash Movement not found',
		'CF0002': 'Only cash movement record of today can be modified',
		'CF0003': 'This cash movement record has been modified by other, please refresh to update',
		'ITEM0001': 'Item not found',
		'USER0001': 'Incorrect Username or Password',
		'USER0002': 'Invalid Warehouse',
		'USER0003': 'User Locked',
		'USER0004': 'Incorrect Username',
		'USER0005': 'Invalid Password Format',
		'USER0006': 'Insufficient Permission',
		'USER0007': 'Incorrect Username or Password. User Locked',
		'USER0008': 'Invalid 2FA Secret',
		'USER0009': 'User not found',
		'USER0010': 'Token expired',
		'USER0011': 'Incorrect Password',
		'USER0012': 'The password must contain at least 10 characters and must include at least 1 uppercase letter, lowercase letter, symbol, and number.',
		'USER0013': 'The password was used before. Please use another password.',
		'USER0014': 'Out of business hours',
		'USER0015': 'Not allowed user login',
		'TRANS0001': 'Salesman not found',
		'TRANS0002': 'Salesman is inactive',
		'TRANS0003': 'Salesman is invalid',
		'TRANS0004': 'Member is not found',
		'TRANS0005': 'This item is not found',
		'TRANS0006': 'This item is not found in this warehouse',
		'TRANS0007': 'Lot no is required',
		'TRANS0008': 'Cart is empty',
		'TRANS0009': 'This item is not available for Q order',
		'TRANS0010': 'Item with this lot no is not found in serial balance',
		'TRANS0011': 'Item with this lot no is out of stock in serial balance',
		'TRANS0012': 'Item with this lot no is less than requested',
		'TRANS0013': 'This item is unique item, quantity cannot be more than 1',
		'TRANS0014': 'Payment is not found ',
		'TRANS0015': 'Price change is not allowed',
		'TRANS0016': 'Payment is not enough',
		'TRANS0017': 'Confirm fail',
		'TRANS0018': 'This Q order is not found',
		'TRANS0019': 'This Q order is canceled already',
		'TRANS0020': 'This Q order is redeemed already',
		'TRANS0021': 'Order cannot be found',
		'TRANS0022': 'Approval is required',
		'TRANS0023': 'Order is cancelled already',
		'TRANS0024': 'Void order fail',
		'TRANS0025': 'Quantity of items returned should be less than original quantity',
		'TRANS0026': 'This is not completed R order',
		'TRANS0027': 'Hold fail',
		'TRANS0028': 'Target order is not valid hold order',
		'TRANS0029': 'Items does not match the transfer out list',
		'TRANS0030': 'Transfer order fail',
		'TRANS0031': 'Target order is not an valid or available transfer out order',
		'TRANS0032': 'The transfer order is not for this warehouse',
		'TRANS0033': 'This item is not allowed for this case',
		'TRANS0034': 'This coupon is invalid for this case',
		'TRANS0035': 'Hold order older than today cannot be updated',
		'TRANS0036': 'This is not today\'s order',
		'TRANS0037': 'Service memo does not support item with price',
		'TRANS0038': 'The some items returned are not found in original order',
		'TRANS0039': 'This item has been reserved by another order',
		'TRANS0040': 'Deposit amount should not be larger than net amount',
		'TRANS0041': 'Credit card payment is larger than required',
		'TRANS0042': 'This coupon is not for this shop',
		'TRANS0043': 'Payment amount by coupon does not match coupon value',
		'TRANS0044': 'This coupon is expired',
		'TRANS0045': 'Deposit amount should not be less than zero',
		'TRANS0046': 'Out of business hours',
		'TRANS0047': 'This item is expired',
		'TRANS0048': 'Approval is required when the deposit amount is lower than %d%% of the total amount',
		'AUTH0001': 'Token expired',
		'AUTH0002': 'Token invalid',
		'AUTH0003': 'Token blacklisted',
		'AUTH0004': 'Token not provided',
		'MEMBER0001': 'Member is not found'
	},
	'zh-Hant': {
		'login': '登入',
		'username': '用戶名稱',
		'password': '密碼',
		'warehouseCode': '店舖編號',
		'loginFailTitle': '登入失敗',
		'loginFailDesc': '用戶名稱或密碼錯誤',
		'mustGrantLocationAccessPermission': '您必須授予訪問位置的權限才能登入。',
		'posFullMenu': 'POS 功能選單',
		'currentShop': '目前店舖',
		'salesOrder': '銷售訂單',
		'serviceMemo': '服務備忘錄',
		'stockCheck': '庫存查詢',
		'orderSearch': '訂單查詢',
		'report': '報表',
		'menu': '選單',
		'shopOrder': '店舖訂單',
		'stockTransferIn': '庫存轉入',
		'stockTransferOut': '庫存轉出',
		'language': '語言',
		'cashMovement': '現金動向',
		'logout': '登出',
		'userManual': '用戶手冊',
		'selectLanguage': '選擇您的語言偏好',
		'changePassword': '輸入現有及新密碼更新',
		'currentPassword': '現有密碼',
		'newPassword': '新密碼',
		'confirmPassword': '確認密碼',
		'currentUser': '目前用戶',
		'update': '更新',
		'validating': '核對中',
		'loading': '載入中',
		'confirming': '確認中',
		'user': '用戶',
		'changeWarehouse': '轉換店舖',
		'scanBarcode': '掃描條碼',
		'editMember': '會員修改',
		'remarkTemplate': '備註模板',
		'salesman': '售貨員',
		'cart': '購物籃',
		'payment': '付款',
		'preview': '預覽',
		'print': '列印',
		'messages': '訊息',
		'salesmanDetails': '售貨員資料',
		'mainSalesman': '主要售貨員',
		'supportSalesman': '支援售貨員',
		'optometristDetails': '視光師資料',
		'optometrist': '視光師',
		'memberDetails': '會員資料',
		'memberNumber': '會員編號',
		'memberName': '會員名稱',
		'mobilePhone': '電話',
		'memberTier': '會員級別',
		'continue': '下一步',
		'clickToSearch': '點擊搜索',
		'allShop': '所有店鋪',
		'salesmanSearch': '搜尋售貨員',
		'optometristSearch': '搜尋視光師',
		'search': '搜尋',
		'memberSearch': '搜尋會員',
		'productCart': '購物籃',
		'qOrder': 'Q 單',
		'rOrder': 'R 單',
		'itemName': '貨品名稱',
		'itemCode': '貨品編號',
		'lotNo': '批號',
		'brandName': '品牌',
		'unitPrice': '單價',
		'qty': '數量',
		'amount': '總額',
		'noLotNo': '沒有批號',
		'remark': '備註',
		'editRemark': '點擊即可編輯備註',
		'edit': '編輯',
		'template': '模板',
		'retailAmount': '零售總額',
		'totalQty': '總數量',
		'totalDiscount': '總折扣',
		'netAmount': '淨總額',
		'discount': '折扣',
		'cancel': '取消',
		'apply': '更改',
		'depositTotal': '訂金總額',
		'methods': '付款方法',
		'paymentMethods': '付款方法',
		'orderType': '訂單類型',
		'paymentAmount': '已付總額',
		'change': '找續',
		'totalResult': '結果',
		'placeOrder': '下訂單',
		'orderedOn': '訂單日期',
		'orderNo': '訂單號碼',
		'customerSignature': '客戶簽名',
		'clear': '清除',
		'createNewOrder': '建立新訂單',
		'printInvoice': '列印發票',
		'updateResults': '更新結果',
		'insertLotNo': '插入批號',
		'tapToEditRemark': '點擊即可編輯備註',
		'salesmanPopupDesc': '點按選擇一位售貨員',
		'memberPopupDesc': '點按選擇一位會員',
		'changeWarehousePopupDesc': '點按選擇一間店舖',
		'changeWarehousesPopupDesc': '點按選擇店舖',
		'remarkPopupDesc': '輸入備註或選擇備註模板',
		'noResult': '未找到任何結果，請更改篩選條件。',
		'promotions': '推廣促銷',
		'approvalCode': '批准代碼',
		'supervisorApproval': '主管批准',
		'confirm': '確認',
		'holdOrder': '暫存訂單',
		'notification': '通知',
		'exclusive': '獨家',
		'multiple': '組合',
		'other': '其他',
		'coupon': '優惠券',
		'pleaseInputDepositAmount': '請輸入訂金',
		'orderPreview': '訂單預覽',
		'balanceDue': '結欠餘額',
		'download': '下載',
		'commitQty': '已訂數量',
		'salesmanSearchHelpText': '搜尋用戶登入名稱 / 姓名',
		'memberSearchHelpText': '搜尋會員編號 / 姓名 / 電話',
		'depositRefund': '退回訂金',
		'selectAll': '全選',
		'deselectAll': '取消全選',
		'addServiceItems': '加入服務項目',
		'insertPromotionCode': '加入優惠碼',
		'missingCriteria': '缺少條件',
		'fulfilledCriteria': '已滿足條件',
		'pattern': '格式：',
		'rangeFrom': '範圍：',
		'rangeTo': '至',
		'currencyCode': '貨幣描述',
		'exchangeRate': '兌換率',
		'amount2': '金額',
		'addPaymentMethod': '新增付款方式',
		'optom': '驗光單',
		'optomRecord': '驗光記錄',
		'optomNew': '新光度',
		'optomFPD': 'PD 遠用',
		'optomNPD': 'PD 近用',
		'optomSPH': 'SPH 球鏡',
		'optomCYL': 'CYL 柱鏡',
		'optomAXIS': 'AXIS 軸線',
		'optomPRISM': 'PRISM 棱鏡',
		'optomBASE': 'BASE 底向',
		'optomADD': 'ADD 附加度數',
		'optomVA': '矯正視力',
		'orderNumber': '單號',
		'expiryDate': '有效期限',
		'recentOrderMembers': '30 日光顧會員',
		'newMembers': '30 日新會員',
		'favoriteMembers': '熟客',
		'serviceItems': '服務項目',
		'favoriteItems': '常用貨品',
		'addToFavorite': '加入常用貨品',
		'removeFromFavorite': '移除常用貨品',
		'addedToFavorite': '已加入常用貨品',
		'removedFromFavorite': '已移除常用貨品',
		'addedBy': '新增者',
		'at': '於',
		'additionalOptions': '額外選項',
		'pleaseSelect': '請選擇',
		'pleaseSelectQROrder': '請選擇 Q 單或 R 單',
		'with': '連 ',
		'consumptionTax': 'GST',
		'advancedSearch': '進階搜尋',
		'typeAndSearch': '輸入及搜尋',
		'reset': '重設',
		'sorting': '條件',
		'ascending': '遞增',
		'descending': '遞減',
		'stockAmount': '庫存總數',
		'appliedFilters': '已套用的篩選條件',
		'filtering': '高級篩選',
		'category': '類別',
		'frames': '框架',
		'framesGJ': '框架 GJ',
		'sunglasses': '太陽眼鏡',
		'sunglassesGJ': '太陽眼鏡 GJ',
		'lenses': '鏡片',
		'contactLenses': '隱形眼鏡',
		'gifts': '禮品',
		'services': '服務',
		'collection': '產品系列',
		'series': '系列',
		'inStock': '庫存總數',
		'retailPrice': '零售價',
		'season': '季度',
		'supplierSeason': '供應商季度',
		'frameShape': '鏡框形狀',
		'age': '年齡層',
		'gender': '性別',
		'origin': '產地',
		'rimType': '鏡框種類',
		'rimColour': '鏡框頻色',
		'lensColour': '鏡片頻色',
		'rimMtl': '前圈物料',
		'rimMtlDetails': '前圈物料（詳細）',
		'armMtl': '鏡腿物料',
		'armMtlDetails': '鏡腿物料（詳細）',
		'replacement': '替換',
		'duration': '配戴日期',
		'bc': 'B.C.',
		'sph': 'SPH',
		'cyl': 'CYL',
		'axis': 'AXIS',
		'add': '加入',
		'sortBy': '排序方式',
		'orderBy': '排列方式',
		'transactionDate': '交易日期',
		'event': '活動',
		'jewellery': '首飾',
		'solution': '方案',
		'appliedPmsFilters': '已套用的篩選條件',
		'appliedOpmsFilters': '已套用 OPMS 的篩選條件',
		'filteringPms': '篩選',
		'filteringOpms': 'OPMS 篩選',
		'return': '退貨',
		'returnPopupDesc': '選擇以下貨品作為退貨',
		'viewOrder': '查看訂單',
		'redeemOrder': '贖回訂單',
		'voidOrder': '取消訂單',
		'retrieveHoldOrder': '提取訂單',
		'searchHelpText': '搜尋貨品名稱 / 貨品編號 / 批號 / 描述',
		'retailPriceFrom': '零售價（由）',
		'retailPriceTo': '零售價（至）',
		'specificShops': '指定店舖',
		'serviceCart': '服務籃',
		'salesQ': '銷售單 Q',
		'salesR': '銷售單 R',
		'onHold': '暫存訂單',
		'serviceQ': '服務單 Q',
		'serviceR': '服務單 R',
		'transfer': '轉貨單',
		'complete': '已完成',
		'incomplete': '未完成',
		'redeemed': '已贖回',
		'ready': '待贖回',
		'all': '全部',
		'orderStatus': '訂單狀態',
		'onHoldSalesOrder': '暫存銷售單',
		'onHoldServiceMemo': '暫存服務單',
		'orderSearchHelpText': '搜尋訂單號碼 / 銷售單 Q 編號 / 售貨員編號 / 會員編號 / 會員名稱 / 會員電話',
		'stockTransferOrderSearchHelpText': '搜尋訂單號碼 / 備註',
		'selectReport': '選擇報表',
		'generate': '製作報表',
		'shop': '店舖',
		'dateFrom': '日期（由）',
		'dateTo': '日期（至）',
		'reportFilters': '報告設定',
		'depositNo': '銷售單 Q 編號',
		'depositDate': '銷售單 Q 日期',
		'reportSalesman': '售貨員',
		'reportBrand': '品牌',
		'reportItem': '貨品',
		'depositStatus': '銷售單 Q 狀態',
		'reportCategory': '類別',
		'docType': '文檔類型',
		'select': '選擇',
		'trxDate': '交易日期',
		'invoiceNo': '發票號碼',
		'modelNo': '型號',
		'salesman1': '售貨員 1',
		'salesman2': '售貨員 2',
		'salesman3': '售貨員 3',
		'caseNo': '案件編號',
		'stockNo': '庫存號碼',
		'stdRetailPrice': '標準零售價格',
		'soldPrice': '售價',
		'fromLoc': '從店舖',
		'toLoc': '到店舖',
		'transferDate': '轉貨日',
		'transerNo': '轉貨編號',
		'orderingNo': '交易編號',
		'export': '匯出',
		'endOfReport': '報表完結',
		'exclude': '不在列表',
		'stockTransfer': '庫存轉換',
		'transferIn': '轉入',
		'transferOut': '轉出',
		'sourceDestination': '來源店舖',
		'destination': '目的店舖',
		'createTransferOut': '建立轉貨單',
		'checkInOrder': '轉入訂單',
		'createDate': '建立日期',
		'transferringItem': '轉貨總數',
		'scanToCheckInItems': '掃描轉入貨品',
		'scanToCheckOutItems': '掃描轉出貨品',
		'resetOrder': '重設轉貨單',
		'removeItem': '移除貨品',
		'checkIn': '轉入庫存',
		'checkOut': '轉出庫存',
		'destinationLocation': '目的店舖',
		'address': '地址',
		'phone': '電話',
		'itemList': '貨品清單',
		'updateQuantity': '更新數量',
		'addItemsByBrand': '按品牌加入產品',
		'selectBrand': '選擇品牌',
		'confirmToCheckOut': '是否確認轉出？',
		'selectRecord': '選擇記錄',
		'recordInfo': '記錄資料',
		'cashier': '收銀機',
		'currency': '貨幣',
		'date': '日期',
		'description': '描述',
		'tapToAddDescription': '點擊編輯描述',
		'paymentInfo': '客戶付款資料',
		'changeAmount': '找續總額',
		'opening': '期初餘額',
		'netPayAmount': '淨付總額',
		'cashFlowEntry': '現金流項目',
		'addItem': '新增項目',
		'balance': '結存',
		'accumulate': '累計',
		'save': '儲存',
		'time': '時間',
		'debit': '提出 (DR)',
		'credit': '存入 (CR)',
		'cashEntryDesc': '選擇或輸入報告條件',
		'assigned': '分配',
		'priceChange': '價格變化',
		'addRemarkTemplate': '新增備註模板',
		'editRemarkTemplate': '編輯備註模板',
		'remarkTemplateSearchHelpText': '搜尋備註內容',
		'createdAt': '新增時間',
		'updatedOn': '修改時間',
		'submit': '提交',
		'remarkContent': '備註內容',
		'status': '狀態',
		'Enabled': '已啟用',
		'Disabled': '已停用',
		'enabled': '已啟用',
		'disabled': '已停用',
		'createdBy': '新增用戶',
		'updatedBy': '修改用戶',
		'MSGRemarkTemplateCreated': '已成功新增備註模板。',
		'MSGRemarkTemplateUpdated': '已成功編輯備註模板。',
		'MSGRemarkTemplateNotCreated': '發生錯誤！未能新增備註模板。',
		'MSGRemarkTemplateNotUpdated': '發生錯誤！未能編輯備註模板。',
		'DP1': 'DP1',
		'SA1': 'SA1',
		'SA3': 'SA3',
		'MSGItemFound': '發現以下貨品',
		'MSGItemNotFound': '未找到貨品',
		'MSGTransferInItemReceived': '已收到貨品',
		'MSGTransferOutCreated': '成功建立轉貨單',
		'MSGTransferOutCreatedFail': '轉貨單建立失敗',
		'MSGTransferInConfirm': '確認收取貨品',
		'MSGTransferOutConfirm': '確認轉出貨品',
		'MSGApprovalFail': '批准認證失敗',
		'MSGFieldsRequired': '請填寫所有必填欄位（*）。',
		'MSGConfirmCashSuccess': '成功建立現金動向',
		'MSGConfirmCashFail': '現金動向建立失敗',
		'OK': '確認',
		'MSGNetworkNotConnected': '網絡未連接',
		'MSGCouponInvalid': '優惠券無效',
		'MSGCouponExpired': '優惠券已過期',
		'MSGCouponNotForCurrentShop': '優惠券不適用於目前的店鋪',
		'MSGShopOrderCreated': '已建立店舖訂單',
		'MSGOrderSaved': '已儲存訂單',
		'MSGPasswordExpired': '您的密碼已過期，請更新密碼',
		'MSGPasswordDifferent': '您的新密碼和確認密碼不同',
		'MSGPasswordUpdated': '密碼已更新',
		'MSGCreditInput': '存入記錄已輸入',
		'MSGDebitInput': '提出記錄已輸入',
		'MSGLogoutConfirm': '確認登出？',
		'MSGIncorrectApprovalCode': '批准代碼錯誤',
		'MSGIncorrectApprovalCodeOrPassword': '批准代碼或密碼錯誤',
		'errorOccurred': '發生錯誤',
		'VALID0001': '請求無效，請輸入有效資料',
		'UnknownError': '未知錯誤',
		'ITEMFILTER0001': '找不到貨品篩選條件',
		'CF0001': '找不到現金動向記錄',
		'CF0002': '只可修改今天的現金動向',
		'CF0003': '現金動向已被修改，請更新',
		'ITEM0001': '找不到貨品',
		'USER0001': '用戶名稱或密碼錯誤',
		'USER0002': '店舖無效',
		'USER0003': '用戶被鎖定',
		'USER0004': '用戶名稱不正確',
		'USER0005': '密碼格式無效',
		'USER0006': '權限不足',
		'USER0007': '用戶名或密碼錯誤。此用戶已被鎖定',
		'USER0008': '雙重認證碼無效',
		'USER0009': '找不到用戶',
		'USER0010': '登入代碼已過期',
		'USER0011': '密碼錯誤',
		'USER0012': '密碼必須包含至少 10 個字符及包含至少 1 個大寫字母小寫字母、符號和數字',
		'USER0013': '您曾經使用此密碼，請改用其他密碼',
		'USER0014': '營業時間已過',
		'USER0015': '不允許用戶登入',
		'TRANS0001': '找不到售貨員',
		'TRANS0002': '售貨員被停用',
		'TRANS0003': '售貨員無效',
		'TRANS0004': '找不到會員',
		'TRANS0005': '找不到此貨品',
		'TRANS0006': '在此店舖找不到此貨品',
		'TRANS0007': '必需有貨品批號',
		'TRANS0008': '購物籃沒有貨品',
		'TRANS0009': '此貨品不可用於銷售單 Q',
		'TRANS0010': '找不到這貨品批號 (in serial balance)',
		'TRANS0011': '這貨品批號缺貨 (in serial balance)',
		'TRANS0012': '這貨品批號庫貨少於要求數量 (in serial balance)',
		'TRANS0013': '這是獨有貨品，數量不能超過 1',
		'TRANS0014': '找不到付款',
		'TRANS0015': '不允許更改價格',
		'TRANS0016': '付款還不夠',
		'TRANS0017': '確認失敗',
		'TRANS0018': '找不到此 Q 訂單',
		'TRANS0019': '此 Q 訂單已經取消',
		'TRANS0020': '此 Q 訂單已經贖回',
		'TRANS0021': '無法找到訂單',
		'TRANS0022': '需要批准',
		'TRANS0023': '訂單已被取消',
		'TRANS0024': '取消訂單失敗',
		'TRANS0025': '退回的貨品數量應少於原有數量',
		'TRANS0026': '此 R 訂單未完成',
		'TRANS0027': '暫存訂單失敗',
		'TRANS0028': '這不是有效暫存訂單',
		'TRANS0029': '貨品不符合轉貨清單',
		'TRANS0030': '轉貨訂單失敗',
		'TRANS0031': '目標訂單不是有效或可用的轉出訂單',
		'TRANS0032': '轉貨訂單不適用於該店舖',
		'TRANS0033': '在這種情況下不允許使用此貨品',
		'TRANS0034': '此優惠券在這種情況下無效',
		'TRANS0035': '不能更改今天以外的暫存訂單',
		'TRANS0036': '這不是今天的訂單',
		'TRANS0037': '服務訂單不支援有價目的貨品',
		'TRANS0038': '在原有的訂單中找不到部分退回的貨品',
		'TRANS0039': '此貨品已被其他訂單保留',
		'TRANS0040': '訂金不應大於淨總額',
		'TRANS0041': '信用卡付款金額不應大於應付款項',
		'TRANS0042': '此優惠券不適用於本店',
		'TRANS0043': '優惠券的付款金額與優惠券價值不符',
		'TRANS0044': '此優惠券已過期',
		'TRANS0045': '訂金金額不應少於零',
		'TRANS0046': '營業時間已結束',
		'TRANS0047': '此產品已過期',
		'TRANS0048': '訂金金額低於總金額的 %d%%，需要批准',
		'AUTH0001': '登入代碼已過期',
		'AUTH0002': '登入代碼無效',
		'AUTH0003': '登入代碼被列入黑名單',
		'AUTH0004': '沒有提供登入代碼',
		'MEMBER0001': '找不到會員'
	},
	'zh-Hans': {
		'login': '登录',
		'username': '用户名称',
		'password': '密码',
		'warehouseCode': '店铺代码',
		'loginFailTitle': '登录失败',
		'loginFailDesc': '用户名称或密码错误',
		'mustGrantLocationAccessPermission': '您必须授予访问位置的权限才能登录。',
		'posFullMenu': 'POS 功能选单',
		'currentShop': '目前店铺',
		'salesOrder': '销售订单',
		'serviceMemo': '服务备忘录',
		'stockCheck': '库存查询',
		'orderSearch': '订单查询',
		'report': '报表',
		'menu': '选单',
		'shopOrder': '店铺订单',
		'stockTransferIn': '库存转入',
		'stockTransferOut': '库存转出',
		'language': '语言',
		'cashMovement': '现金动向',
		'logout': '登出',
		'userManual': '用户手册',
		'selectLanguage': '选择您的语言偏好',
		'changePassword': '输入现有及新密码更新',
		'currentPassword': '现有密码',
		'newPassword': '新密码',
		'confirmPassword': '确认密码',
		'currentUser': '目前用户',
		'update': '更新',
		'validating': '核对中',
		'loading': '载入中',
		'confirming': '确认中',
		'user': '用户',
		'changeWarehouse': '转换店铺',
		'scanBarcode': '扫描条码',
		'editMember': '会员修改',
		'remarkTemplate': '备注模板',
		'salesman': '售货员',
		'cart': '购物篮',
		'payment': '支付',
		'preview': '预览',
		'print': '列印',
		'messages': '讯息',
		'salesmanDetails': '售货员资料',
		'mainSalesman': '主要售货员',
		'supportSalesman': '支援售货员',
		'optometristDetails': '视光师资料',
		'optometrist': '视光师',
		'memberDetails': '会员资料',
		'memberNumber': '会员编号',
		'memberName': '会员名称',
		'mobilePhone': '电话',
		'memberTier': '会员级别',
		'continue': '下一步',
		'clickToSearch': '点击搜索',
		'allShop': '所有店铺',
		'salesmanSearch': '搜寻售货员',
		'optometristSearch': '搜寻视光师',
		'search': '搜寻',
		'memberSearch': '搜寻会员',
		'productCart': '购物篮',
		'qOrder': 'Q 单',
		'rOrder': 'R 单',
		'itemName': '货品名称',
		'itemCode': '货品编号',
		'lotNo': '批号',
		'brandName': '品牌',
		'unitPrice': '单价',
		'qty': '数量',
		'amount': '总额',
		'noLotNo': '没有批号',
		'remark': '备注',
		'editRemark': '点击即可编辑备注',
		'edit': '编辑',
		'template': '模板',
		'retailAmount': '零售总额',
		'totalQty': '总数量',
		'totalDiscount': '总折扣',
		'netAmount': '净总额',
		'discount': '折扣',
		'cancel': '取消',
		'apply': '更改',
		'depositTotal': '订金总额',
		'methods': '付款方法',
		'paymentMethods': '付款方法',
		'orderType': '订单类型',
		'paymentAmount': '已付总额',
		'change': '找续',
		'totalResult': '结果',
		'placeOrder': '下订单',
		'orderedOn': '订单日期',
		'orderNo': '订单号码',
		'customerSignature': '客户签名',
		'clear': '清除',
		'createNewOrder': '建立新订单',
		'printInvoice': '列印发票',
		'updateResults': '更新结果',
		'insertLotNo': '插入批号',
		'tapToEditRemark': '点击即可编辑备注',
		'salesmanPopupDesc': '点按选择一位售货员',
		'memberPopupDesc': '点按选择一位会员',
		'changeWarehousePopupDesc': '点按选择一间店铺',
		'changeWarehousesPopupDesc': '点按选择店铺',
		'remarkPopupDesc': '输入备注或选择备注模板',
		'noResult': '未找到任何结果，请更改筛选条件。',
		'promotions': '推广促销',
		'approvalCode': '批准代码',
		'supervisorApproval': '主管批准',
		'confirm': '确认',
		'holdOrder': '暂存订单',
		'notification': '通知',
		'exclusive': '独家',
		'multiple': '组合',
		'other': '其他',
		'coupon': '优惠券',
		'pleaseInputDepositAmount': '请输入订金',
		'orderPreview': '订单预览',
		'balanceDue': '结欠余额',
		'download': '下载',
		'commitQty': '已订数量',
		'salesmanSearchHelpText': '搜寻用户登入名称 / 姓名',
		'memberSearchHelpText': '搜寻会员编号 / 姓名 / 电话',
		'depositRefund': '退回订金',
		'selectAll': '全选',
		'deselectAll': '取消全选',
		'addServiceItems': '加入服务项目',
		'insertPromotionCode': '加入优惠码',
		'missingCriteria': '缺少条件',
		'fulfilledCriteria': '已满足条件',
		'pattern': '格式：',
		'rangeFrom': '范围：',
		'rangeTo': '至',
		'currencyCode': '货币描述',
		'exchangeRate': '兑换率',
		'amount2': '金额',
		'addPaymentMethod': '添加付款方式',
		'optom': '验光单',
		'optomRecord': '验光记录',
		'optomNew': '新光度',
		'optomFPD': 'PD 远用',
		'optomNPD': 'PD 近用',
		'optomSPH': 'SPH 球镜',
		'optomCYL': 'CYL 柱镜',
		'optomAXIS': 'AXIS 轴线',
		'optomPRISM': 'PRISM 棱镜',
		'optomBASE': 'BASE 底向',
		'optomADD': 'ADD 附加度数',
		'optomVA': '矫正视力',
		'orderNumber': '单号',
		'expiryDate': '有效期限',
		'recentOrderMembers': '30 日光顾会员',
		'newMembers': '30 日新会员',
		'favoriteMembers': '熟客',
		'serviceItems': '服务项目',
		'favoriteItems': '常用货品',
		'addToFavorite': '加入常用货品',
		'removeFromFavorite': '移除常用货品',
		'addedToFavorite': '已加入常用货品',
		'removedFromFavorite': '已移除常用货品',
		'addedBy': '新增者',
		'at': '于',
		'additionalOptions': '额外选项',
		'pleaseSelect': '请选择',
		'pleaseSelectQROrder': '请选择 Q 单或 R 单',
		'with': '连 ',
		'consumptionTax': 'GST',
		'advancedSearch': '进阶搜寻',
		'typeAndSearch': '输入及搜寻',
		'reset': '重设',
		'sorting': '条件',
		'ascending': '递增',
		'descending': '递减',
		'stockAmount': '库存总数',
		'appliedFilters': '已套用的筛选条件',
		'filtering': '高级筛选',
		'category': '类别',
		'frames': '框架',
		'framesGJ': '框架 GJ',
		'sunglasses': '太阳眼镜',
		'sunglassesGJ': '太阳眼镜 GJ',
		'lenses': '镜片',
		'contactLenses': '隐形眼镜',
		'gifts': '礼品',
		'services': '服务',
		'collection': '产品系列',
		'series': '系列',
		'inStock': '库存总数',
		'retailPrice': '零售价',
		'season': '季度',
		'supplierSeason': '供应商季度',
		'frameShape': '镜框形状',
		'age': '年龄层',
		'gender': '性别',
		'origin': '产地',
		'rimType': '镜框种类',
		'rimColour': '镜框频色',
		'lensColour': '镜片频色',
		'rimMtl': '前圈物料',
		'rimMtlDetails': '前圈物料（详细）',
		'armMtl': '镜腿物料',
		'armMtlDetails': '镜腿物料（详细）',
		'replacement': '替换',
		'duration': '配戴日期',
		'bc': 'B.C.',
		'sph': 'SPH',
		'cyl': 'CYL',
		'axis': 'AXIS',
		'add': '添加',
		'sortBy': '排序方式',
		'orderBy': '排列方式',
		'transactionDate': '交易日期',
		'event': '活动',
		'jewellery': '首饰',
		'solution': '方案',
		'appliedPmsFilters': '已套用的筛选条件',
		'appliedOpmsFilters': '已套用 OPMS 的筛选条件',
		'filteringPms': '筛选',
		'filteringOpms': 'OPMS 筛选',
		'return': '退货',
		'returnPopupDesc': '选择以下项目作为退货',
		'viewOrder': '查看订单',
		'redeemOrder': '赎回订单',
		'voidOrder': '取消订单',
		'retrieveHoldOrder': '提取订单',
		'searchHelpText': '搜寻货品名称 / 货品编号 / 批号 / 描述',
		'retailPriceFrom': '零售价（由）',
		'retailPriceTo': '零售价（至）',
		'specificShops': '指定店铺',
		'serviceCart': '服务篮',
		'salesQ': '销售单 Q',
		'salesR': '销售单 R',
		'onHold': '暂存订单',
		'serviceQ': '服务单 Q',
		'serviceR': '服务单 R',
		'transfer': '转货单',
		'complete': '已完成',
		'incomplete': '未完成',
		'redeemed': '已赎回',
		'ready': '待赎回',
		'all': '全部',
		'orderStatus': '订单状态',
		'onHoldSalesOrder': '暂存销售单',
		'onHoldServiceMemo': '暂存服务单',
		'orderSearchHelpText': '搜寻订单号码 / 销售单 Q 编号 / 售货员编号 / 会员编号 / 会员名称 / 会员电话',
		'stockTransferOrderSearchHelpText': '搜寻订单号码 / 备注',
		'selectReport': '选择报表',
		'generate': '制作报表',
		'shop': '店铺',
		'dateFrom': '日期（由）',
		'dateTo': '日期（至）',
		'reportFilters': '报告设定',
		'depositNo': '销售单 Q 编号',
		'depositDate': '销售单 Q 日期',
		'reportSalesman': '售货员',
		'reportBrand': '品牌',
		'reportItem': '货品',
		'depositStatus': '销售单 Q 状态',
		'reportCategory': '类别',
		'docType': '文档类型',
		'select': '选择',
		'trxDate': '交易日期',
		'invoiceNo': '发票号码',
		'modelNo': '型号',
		'salesman1': '售货员 1',
		'salesman2': '售货员 2',
		'salesman3': '售货员 3',
		'caseNo': '案件编号',
		'stockNo': '库存号码',
		'stdRetailPrice': '标准零售价格',
		'soldPrice': '售价',
		'fromLoc': '从店铺',
		'toLoc': '到店铺',
		'transferDate': '转货日',
		'transerNo': '转货编号',
		'orderingNo': '交易编号',
		'export': '汇出',
		'endOfReport': '报表完结',
		'exclude': '不在列表',
		'stockTransfer': '库存转换',
		'transferIn': '转入',
		'transferOut': '转出',
		'sourceDestination': '来源店铺',
		'destination': '目的店铺',
		'createTransferOut': '建立转货单',
		'checkInOrder': '转入订单',
		'createDate': '建立日期',
		'transferringItem': '转货总数',
		'scanToCheckInItems': '扫描转入货品',
		'scanToCheckOutItems': '扫描转出货品',
		'resetOrder': '重设转货单',
		'removeItem': '移除货品',
		'checkIn': '转入库存',
		'checkOut': '转出库存',
		'destinationLocation': '目的店铺',
		'address': '地址',
		'phone': '电话',
		'itemList': '项目清单',
		'updateQuantity': '更新数量',
		'addItemsByBrand': '按品牌加入产品',
		'selectBrand': '选择品牌',
		'confirmToCheckOut': '是否确认转出？',
		'selectRecord': '选择记录',
		'recordInfo': '记录资料',
		'cashier': '收银机',
		'currency': '货币',
		'date': '日期',
		'description': '描述',
		'tapToAddDescription': '点击编辑描述',
		'paymentInfo': '客户付款资料',
		'changeAmount': '找续总额',
		'opening': '期初余额',
		'netPayAmount': '净付总额',
		'cashFlowEntry': '现金流项目',
		'addItem': '新增项目',
		'balance': '结存',
		'accumulate': '累计',
		'save': '储存',
		'time': '时间',
		'debit': '提出 (DR)',
		'credit': '存入 (CR)',
		'cashEntryDesc': '选择或输入报告条件',
		'assigned': '分配',
		'priceChange': '价格变化',
		'addRemarkTemplate': '添加备注模板',
		'editRemarkTemplate': '编辑备注模板',
		'remarkTemplateSearchHelpText': '搜寻备注内容',
		'createdAt': '添加时间',
		'updatedOn': '修改时间',
		'submit': '提交',
		'remarkContent': '备注内容',
		'status': '状态',
		'Enabled': '已启用',
		'Disabled': '已停用',
		'enabled': '已启用',
		'disabled': '已停用',
		'createdBy': '新增用户',
		'updatedBy': '修改用户',
		'MSGRemarkTemplateCreated': '已成功新增备注模板。',
		'MSGRemarkTemplateUpdated': '已成功编辑备注模板。',
		'MSGRemarkTemplateNotCreated': '发生错误！未能新增备注模板。',
		'MSGRemarkTemplateNotUpdated': '发生错误！未能编辑备注模板。',
		'DP1': 'DP1',
		'SA1': 'SA1',
		'SA3': 'SA3',
		'MSGItemFound': '发现以下货品',
		'MSGItemNotFound': '未找到货品',
		'MSGTransferInItemReceived': '已收到货品',
		'MSGTransferOutCreated': '成功建立转货单',
		'MSGTransferOutCreatedFail': '转货单建立失败',
		'MSGTransferInConfirm': '确认收取货品',
		'MSGTransferOutConfirm': '确认转出货品',
		'MSGApprovalFail': '批准认证失败',
		'MSGFieldsRequired': '请填写所有必填栏位（*）。',
		'MSGConfirmCashSuccess': '成功建立现金动向',
		'MSGConfirmCashFail': '现金动向建立失败',
		'OK': '确认',
		'MSGNetworkNotConnected': '网络未连接',
		'MSGCouponInvalid': '优惠券无效',
		'MSGCouponExpired': '优惠券已过期',
		'MSGCouponNotForCurrentShop': '优惠券不适用于目前的店铺',
		'MSGShopOrderCreated': '已建立店铺订单',
		'MSGOrderSaved': '已储存订单',
		'MSGPasswordExpired': '您的密码已过期，请更新密码',
		'MSGPasswordDifferent': '您的新密码和确认密码不同',
		'MSGPasswordUpdated': '密码已更新',
		'MSGCreditInput': '存入记录已输入',
		'MSGDebitInput': '提出记录已输入',
		'MSGLogoutConfirm': '确认登出？',
		'MSGIncorrectApprovalCode': '批准代码错误',
		'MSGIncorrectApprovalCodeOrPassword': '批准代码或密码错误',
		'errorOccurred': '发生错误',
		'VALID0001': '请求无效，请输入有效资料',
		'UnknownError': '未知错误',
		'ITEMFILTER0001': '找不到货品筛选条件',
		'CF0001': '找不到现金动向记录',
		'CF0002': '只可修改今天的现金动向',
		'CF0003': '现金动向已被修改，请更新',
		'ITEM0001': '找不到货品',
		'USER0001': '用户名称或密码错误',
		'USER0002': '店铺无效',
		'USER0003': '用户被锁定',
		'USER0004': '用户名称不正确',
		'USER0005': '密码格式无效',
		'USER0006': '权限不足',
		'USER0007': '用户名或密码错误。此用户已被锁定',
		'USER0008': '双重认证码无效',
		'USER0009': '找不到用户',
		'USER0010': '登入代码已过期',
		'USER0011': '密码错误',
		'USER0012': '密码必须包含至少 10 个字符及包含至少 1 个大写字母小写字母、符号和数字',
		'USER0013': '您曾经使用此密码，请改用其他密码',
		'USER0014': '营业时间已过',
		'USER0015': '不允许用户登入',
		'TRANS0001': '找不到售货员',
		'TRANS0002': '售货员被停用',
		'TRANS0003': '售货员无效',
		'TRANS0004': '找不到会员',
		'TRANS0005': '找不到此货品',
		'TRANS0006': '在此店铺找不到此货品',
		'TRANS0007': '必需有货品批号',
		'TRANS0008': '购物篮没有货品',
		'TRANS0009': '此货品不可用于销售单 Q',
		'TRANS0010': '找不到这货品批号 (in serial balance)',
		'TRANS0011': '这货品批号缺货 (in serial balance)',
		'TRANS0012': '这货品批号库货少于要求数量 (in serial balance)',
		'TRANS0013': '这是独有货品，数量不能超过 1',
		'TRANS0014': '找不到付款',
		'TRANS0015': '不允许更改价格',
		'TRANS0016': '付款还不够',
		'TRANS0017': '确认失败',
		'TRANS0018': '找不到此 Q 订单',
		'TRANS0019': '此 Q 订单已经取消',
		'TRANS0020': '此 Q 订单已经赎回',
		'TRANS0021': '无法找到订单',
		'TRANS0022': '需要批准',
		'TRANS0023': '订单已被取消',
		'TRANS0024': '取消订单失败',
		'TRANS0025': '退回的货品数量应少于原有数量',
		'TRANS0026': '此 R 订单未完成',
		'TRANS0027': '暂存订单失败',
		'TRANS0028': '这不是有效暂存订单',
		'TRANS0029': '货品不符合转货清单',
		'TRANS0030': '转货订单失败',
		'TRANS0031': '目标订单不是有效或可用的转出订单',
		'TRANS0032': '转货订单不适用于该店铺',
		'TRANS0033': '在这种情况下不允许使用此货品',
		'TRANS0034': '此优惠券在这种情况下无效',
		'TRANS0035': '不能更改今天以外的暂存订单',
		'TRANS0036': '这不是今天的订单',
		'TRANS0037': '服务订单不支援有价目的货品',
		'TRANS0038': '在原有的订单中找不到部分退回的货品',
		'TRANS0039': '此货品已被其他订单保留',
		'TRANS0040': '订金不应大于净总额',
		'TRANS0041': '信用卡付款金额不应大于应付款项',
		'TRANS0042': '此优惠券不适用于本店',
		'TRANS0043': '优惠券的付款金额与优惠券价值不符',
		'TRANS0044': '此优惠券已过期',
		'TRANS0045': '订金金额不应少于零',
		'TRANS0046': '营业时间已结束',
		'TRANS0047': '此产品已过期',
		'TRANS0048': '订金金额低于总金额的 %d%%，需要批准',
		'AUTH0001': '登入代码已过期',
		'AUTH0002': '登入代码无效',
		'AUTH0003': '登入代码被列入黑名单',
		'AUTH0004': '没有提供登入代码',
		'MEMBER0001': '找不到会员'
	}
});

loc.Lang = loc.getLanguage();

loc.changeLang = (lang) => {
	loc.setLanguage(lang);
	loc.Lang = loc.getLanguage();
};

setDefaultLangFromDevice();

function setDefaultLangFromDevice() {
	if (localStorage.getItem('lang')) {
		loc.changeLang(localStorage.getItem('lang'));
		return;
	}

	const support_lang = loc.getAvailableLanguages();
	const device_lang = loc.getInterfaceLanguage();
	let found = false;

	support_lang.map((m) => {
		if (device_lang.includes(m)) {
			loc.changeLang(m);
			found = true;
		}
	});
	if (!found) {
		if (device_lang === 'zh-HK') {
			loc.changeLang('zh-Hant');
		} else if (device_lang === 'zh-CN') {
			loc.changeLang('zh-Hans');
		} else if (device_lang.includes('zh')) {
			loc.changeLang('zh-Hant');
		}
	}
}

export default loc;
