import {
	takeEvery, call, put, all
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actions_ui from './actions_ui';

// get data here, actions are dispatch into here and then put action into reducers
function* fetchData(action) {
	try {
		// console.log('saga action: ', action);
		const data = yield call(action.api, action);
		yield put({ type: action.cons.SUCCESS, data });
	} catch (e) {
		if (e.response) {
			// console.log('saga error data: ', e.response.data);
			// console.log('saga error status: ', e.response.status);
			// console.log('saga error headers: ', e.response.headers);
		} else if (e.request) {
			// console.log('saga error request: ', e.request);
		} else {
			// console.log('saga error message: ', e.message);
		}
		// console.log('saga error config: ', e.config);
		// console.log('saga error: ', e);
		// console.log('saga action: ', action);
		yield put({
			type: action.cons.FAILURE,
			error: e,
			api: action.cons,
			timestamp: Date.now(),
		});
	}
}

function* updateLayout(action) {
	try {
		yield put({ ...action, type: action.cons.SUCCESS });
	} catch (e) {
		// console.log(e);
		yield put({
			type: action.cons.FAILURE,
			error: e,
			api: action.cons,
			timestamp: Date.now()
		});
	}
}

function* allSagas() {
	const all_actions = [];
	for (let act in actions) {
		all_actions.push(takeEvery(actions[act]().cons.FETCHING, fetchData));
	}
	for (let act in actions_ui) {
		all_actions.push(takeEvery(actions_ui[act]().cons.REQUEST_UPDATE, updateLayout));
	}
	yield all(all_actions);
}

export default allSagas;
