import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './MenuBarButton.less';

class MenuBarButton extends PureComponent {
	render() {
		const { icon, label, className, href } = this.props;
		return (
			<div className={ className }>
				<NavLink
					to={ `/app/${href}` }
					className={ classnames('uk-button uk-button-default uk-border-uk-width-1-1 uk-text-small', styles.button) }
					activeClassName={ styles.active }
				>
					<FontAwesomeIcon icon={ icon } className="fa-3x" />
					<br />
					<div className={ styles.label }>{ label }</div>
				</NavLink>
			</div>
		);
	}
}

MenuBarButton.propTypes = {
	icon: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
};

MenuBarButton.defaultProps = {
	className: '',
};

export default MenuBarButton;