exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".W6KOZ {\n  position: relative;\n  width: 30%;\n  min-height: 410px;\n  margin: 40px auto 40px;\n  text-align: center;\n  z-index: 1;\n}\n.W6KOZ ._19pZu {\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  margin-top: 20px;\n  text-align: center;\n}\n.W6KOZ ._19pZu::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n.W6KOZ ._19pZu::-moz-placeholder {\n  text-transform: capitalize;\n}\n.W6KOZ ._19pZu:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.W6KOZ ._19pZu::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.W6KOZ ._19pZu::placeholder {\n  text-transform: capitalize;\n}\n.W6KOZ .gpF6i {\n  margin-top: 40px;\n}\n._1XtTO > li > a {\n  min-height: 40px;\n}\n._35zQz {\n  padding: 25px;\n  min-width: 400px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"form": "W6KOZ",
	"input": "_19pZu",
	"button": "gpF6i",
	"nav": "_1XtTO",
	"copyright": "_35zQz"
};