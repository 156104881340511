import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import { Set } from 'immutable';
import loc from 'Components/languages';
import { resetError } from 'Redux/actions_ui';
import Modal from 'Components/modal';
import styles from './ModalError.less';

class ModalError extends PureComponent {
	constructor() {
		super();
		this.state = {
			active: new Set(),
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 300px)',
				right: 'calc(50% - 300px)',
				maxHeight: '450px',
				padding: 0,
			},
		};
		this.onDismissHandler = this.onDismissHandler.bind(this);
		this.renderDetail = this.renderDetail.bind(this);
	}

	componentDidUpdate(prevProps) {
		let { active } = this.state;
		const { errorInfo } = this.props;
		const prevErrorInfo = prevProps.errorInfo;
		if (errorInfo && errorInfo !== prevErrorInfo) {
			if (errorInfo.length === 0) {
				active = new Set();
			} else {
				errorInfo.forEach((error, index) => {
					if (!active.has(index)) {
						active = active.add(index);
					}
				});
			}
			this.setState({
				active,
			});
		}
	}

	onDismissHandler(index) {
		const { errorInfo } = this.props;
		const { error } = errorInfo.get(index);
		if (error.message === 'TOKEN_INVALID' || error.message === 'TOKEN_EXPIRED') {
			const user = user || (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
			localStorage.removeItem('user');
			if (user && user.login_url) {
				location.href = `/login/${user.login_url}`;
			} else {
				location.href = '/';
			}
		}
		const { active } = this.state;
		this.setState({
			active: active.delete(index),
		});
		this.props.resetError(index);
	}

	renderDetail(detail) {
		if (_.isObject(detail)) {
			return (
				<ul className="mt-3">
					{
						_.map(detail, (value, index) => (
							<li key={ `modal-error-detail-${index}` }>
								<strong>{ index }: </strong>
								{
									_.isObject(value) ? value.map(
										(v, j) => (
											<p key={ `modal-error-detail-${index}-${j}` }>
												{ v }
											</p>
										)
									) : value
								}
							</li>
						))
					}
				</ul>
			);
		} else if (detail) {
			return (
				<p className={ classnames('mb-0', styles.pre) }>
					{ detail }
				</p>
			);
		} else {
			return null;
		}
	}

	render() {
		const { errorInfo } = this.props;
		const { active } = this.state;
		if (errorInfo.size > 0) {
			return (
				<Fragment>
					{
						errorInfo.map((data, index) => {
							const onDismissHandler = this.onDismissHandler.bind(this, index);
							const error = data.error;
							return (
								<Modal
									key={ `modal-error-${index}` }
									title={ loc.errorOccurred }
									isOpen={ active.has(index) }
									onToggle={ onDismissHandler }
									style={ this.modalStyle }
								>
									<div className={ styles.container }>
										<p>
											{ `${loc[error.code] ? loc[error.code] : error.message} (${error.code})` }
										</p>
										{ this.renderDetail(error.detail) }
										<div className="uk-text-right">
											<button
												className="uk-button uk-button-primary"
												type="button"
												onClick={ onDismissHandler }
											>
												{ loc.OK }
											</button>
										</div>
									</div>
								</Modal>
							);
						})
					}
				</Fragment>
			);
		}
		return null;
	}
}

export default connect(
	state => ({
		errorInfo: state.errorInfo,
	}),
	{
		resetError,
	}
)(ModalError);