import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import styles from './Top.less';

class Top extends PureComponent {
	constructor() {
		super();
		this.onClickPrevHandler = this.onClickPrevHandler.bind(this);
	}

	onClickPrevHandler(e) {
		const { onClickPrev, history } = this.props;
		e.preventDefault();
		if (onClickPrev) {
			onClickPrev();
		} else {
			history.push('/app/home');
		}
	}

	render() {
		const { name, desc, disableBack } = this.props;
		return (
			<div className={ styles.progressBar } data-uk-grid>
				<div className="uk-width-auto uk-flex-inline uk-flex-middle">
					<div>
						<button
							className={ classnames('uk-button uk-button-primary uk-inline uk-position-relative', styles.button) }
							onClick={ this.onClickPrevHandler }
							disabled={ disableBack }
						>
							<FontAwesomeIcon
								icon={ faChevronLeft }
								className="fa-2x uk-position-center"
							/>
						</button>
					</div>
					<div className="uk-margin-left uk-margin-remove-top uk-h4 uk-text-bold uk-text-primary uk-text-uppercase">
						{ loc[name] }
						{ desc && `: ${desc}` }
					</div>
				</div>
				{ this.props.children }
			</div>
		);
	}
}

Top.propTypes = {
	name: PropTypes.string.isRequired,
	desc: PropTypes.string,
	disableBack: PropTypes.bool,
	onClickPrev: PropTypes.func,
};

export default withRouter(Top);