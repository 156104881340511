import { jsonToQueryStr } from '../helpers/helpers';

export const apiUrl = `${process.env.API_ENDPOINT}/api/v1`;

// All APIs are configured here and they would be called through actions.js
// If the API is successful, the received data response would be returned to corresponding reducer.data
// If the API is fail or any error is caught, the error response would be returned to corresponding reducer.error

async function fetchData({ path, method, body, headers = {}, user = null }) {
	try {
		const url = `${apiUrl}${path}`;

		const data = {
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...headers,
			},
		};
		if (method === 'POST' || method === 'PUT') {
			data.body = JSON.stringify(body);
		}

		user = user || (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
		if (user) {
			if (user.access_token) {
				data.headers.Authorization = `Bearer ${user.access_token}`;
			}
			if (user.login_url && !data.headers.Warehouse) {
				data.headers.Warehouse = user.login_url;
			}
		}

		const response = await fetch(url, data);
		const responseJson = await response.json();

		if (response.status === 200) {
			return responseJson;
		}
		throw responseJson;
	} catch (err) {
		throw err;
	}
}

export async function postLogin(action) {
	const path = '/login';
	const method = 'POST';
	let body = action.payload;
	let request = { path, method, body };
	let login_url = body.login_url;
	if (login_url) {
		delete request.body.login_url;
		request.headers = {
			Warehouse: login_url,
		};
	}

	try {
		const response = await fetchData(request);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getCurrentUser() {
	const path = '/user/current';
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function refreshAccessToken(action) {
	const param = { ...action.payload.apiData };

	const path = '/refresh';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getSalesman(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/user/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function updateSalesOrder(action) {
	const param = { ...action.payload.apiData };

	const path = '/transaction/process';
	const method = 'POST';
	const body = param;

	try {
		if (action.reset) {
			return { ...action.payload };
		}
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function confirmSalesOrder(action) {
	const param = { ...action.payload.apiData };

	const path = '/transaction/confirm';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function retrieveHoldOrder(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function holdSalesOrder(action) {
	const param = { ...action.payload.apiData };

	const path = '/transaction/holdOrder';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function updateServiceMemo(action) {
	const param = { ...action.payload.apiData, is_service_memo: true };

	const path = '/transaction/process';
	const method = 'POST';
	const body = param;

	try {
		if (action.reset) {
			return { ...action.payload };
		}
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function confirmServiceMemo(action) {
	const param = { ...action.payload.apiData, is_service_memo: true };

	const path = '/transaction/confirm';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function holdServiceMemo(action) {
	const param = { ...action.payload.apiData, is_service_memo: true };

	const path = '/transaction/holdOrder';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function updateShopOrder(action) {
	const param = { ...action.payload.apiData, is_shop_order: true };

	const path = '/transaction/process';
	const method = 'POST';
	const body = param;

	try {
		if (action.reset) {
			return { ...action.payload };
		}
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function confirmShopOrder(action) {
	const param = { ...action.payload.apiData, is_shop_order: true };

	const path = '/transaction/confirm';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return { ...action.payload, apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function holdShopOrder(action) {
	const param = { ...action.payload.apiData, is_shop_order: true };

	const path = '/transaction/holdOrder';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function retrieveHoldShopOrder(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getPmsFilters() {
	const path = '/item/getPmsFilters';
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getPmsFilterOptions(action) {
	const queryString = `?filter_key=${action.payload}`;
	const path = `/item/getPmsFilterOptions${queryString}`;
	const method = 'GET';
	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getOpmsFilters() {
	const path = '/item/getOpmsFilters';
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getOpmsFilterOptions(action) {
	const queryString = `?filter_key=${action.payload}`;
	const path = `/item/getOpmsFilterOptions${queryString}`;
	const method = 'GET';
	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getMember(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/member/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getMemberDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/member/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getItem(action) {
	const path = '/item/findAllByPageAndOptions';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function batchGetItems(action) {
	const path = '/item/batchFindAllByItemCodes';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getFavoriteItems(action) {
	const path = '/item/findAllByPageAndOptions';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function setItemToCurrentShop(action) {
	const path = '/item/setToCurrentShop';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function unsetItemFromCurrentShop(action) {
	const path = '/item/unsetFromCurrentShop';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getServiceItems(action) {
	const path = '/serviceItem/findAll';
	const method = 'GET';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getPayments(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/paymentMethod/findAll${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getRemark(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/remark/findAllByPage${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getSalesRemarkTemplates(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/salesRemarkTemplate/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getSalesRemarkTemplateDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/salesRemarkTemplate/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function createSalesRemarkTemplate(action) {
	const path = '/salesRemarkTemplate/create';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function updateSalesRemarkTemplate(action) {
	const path = '/salesRemarkTemplate/update';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getSalesRemarkTemplateOptions() {
	const path = '/options/salesRemarkTemplate';
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getExchangeRates(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/exchangeRate/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getLotItem(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/item/findItemByInput${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}
export async function getLotNo(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/item/lot/findAllByPageAndItemCode${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getTransactions(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function convertQtoR(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/convertQtoR${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return { apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function depositRefund(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/depositRefund${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return { apiData: response };
	} catch (err) {
		throw err;
	}
}

export async function voidOrder(action) {
	const path = '/transaction/voidOrder';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function postApproval(action) {
	const path = '/user/2fa/verify';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReturnItem(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/transaction/findItemsForReturn${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function changePassword(action) {
	const path = '/user/changePassword';
	const method = 'PUT';
	const body = action.payload;
	const user = action.user;

	try {
		const response = await fetchData({ path, method, body, user });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function previewOrder(action) {
	const param = { ...action.payload.apiData };

	const path = '/transaction/preview';
	const method = 'POST';
	const body = param;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function viewOrder(action) {
	const path = '/transaction/viewOrder';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function printOrder(action) {
	const path = '/transaction/print';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getTransferInOrder(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/stockTransfer/listTransferInOrdersByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getTransferOutOrder(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/stockTransfer/listTransferOutOrdersByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getTransferDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/stockTransfer/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function printTransferOrder(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/stockTransfer/print${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getWarehouse(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/warehouse/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getCurrentWarehouse(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/warehouse/current${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getWarehouseByFilter(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/warehouse/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function setUserCurrentShop(action) {
	const path = '/user/setToCurrentShop';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function unsetUserCurrentShop(action) {
	const path = '/user/unsetFromCurrentShop';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function setFavoriteMember(action) {
	const path = '/member/setFavoriteMember';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function unsetFavoriteMember(action) {
	const path = '/member/unsetFavoriteMember';
	const method = 'PUT';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function confirmTransferInOrder(action) {
	const path = '/stockTransfer/transferIn';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function createTransferOutOrder(action) {
	const path = '/stockTransfer/transferOut';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function confirmCashMovement(action) {
	const path = '/cashMovement/confirm';
	const method = 'POST';
	const body = action.payload;

	try {
		const response = await fetchData({ path, method, body });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getCashMovementRecords(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/cashMovement/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getCashMovementDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/cashMovement/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function changeWarehouse(action) {
	const path = `/users/${action.payload.user_id}/shop/${action.payload.wh_code}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function findGiftCouponByCamera(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/coupon/findGiftCouponByInput${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function findGiftCouponByInput(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/coupon/findGiftCouponByInput${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function findCashCouponByInput(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/coupon/findCashCouponByInput${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReports(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/findAll${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReportDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReportDetailsAsync(action) {
	action.payload.async = '1';
	delete action.payload.job_id;

	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReportFilters(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/filters${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReportOptions(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/options${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getReportExport(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;
	const path = `/report/export/${action.fileType}${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getRftInfo(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/rftInfo/findAllByPageAndOptions${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getRftInfoDetails(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/rftInfo/details${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export async function getSpecialOptions(action) {
	const queryString = `?${jsonToQueryStr(action.payload)}`;

	const path = `/specialOptions/findAll${queryString}`;
	const method = 'GET';

	try {
		const response = await fetchData({ path, method });
		return response;
	} catch (err) {
		throw err;
	}
}

export function postLogout(errorCode) {
	if (errorCode) { return errorCode; }
	return {};
}

export async function clearCacheSalesOrder() {
	return {};
}

export async function clearCacheServiceMemo() {
	return {};
}

export async function clearCacheShopOrder() {
	return {};
}
