import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { defaultStartPath } from 'Constants/defaultValues';
import MainRoute from 'Routes';
import login from 'Routes/login';
import Error404 from 'Routes/error';
import './App.css';

const InitialPath = ({ component: Component, loginInfo, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			(loginInfo && loginInfo.data && loginInfo.data.access_token)
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>}
	/>
);

class App extends PureComponent {
	render() {
		const { /* location, */ match, loginInfo } = this.props;
		if (location.pathname === '/' || location.pathname === '/app' || location.pathname === '/app/') {
			return (<Redirect to={defaultStartPath} />);
		}
		return (
			<Switch>
				<InitialPath
					path={ `${match.url}app` }
					loginInfo={ loginInfo }
					component={ MainRoute }
				/>
				<Route path="/login/:warehouse" component={ login } />
				<Route path="/login" component={ login } />
				<Route path="/error" render={ (routeProps) => <Error404 {...routeProps} showSidebar={ true } /> } />
				<Redirect to="/error" />
			</Switch>
		);
	}
}

export default connect(
	state => ({
		loginInfo: state.loginInfo,
		appLanguage: state.appLanguage,
	})
)(App);