import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

class Button extends PureComponent {
	render() {
		const { theme, text, type, padding, arrow, onClick, disabled } = this.props;
		let className = 'uk-button-default';
		switch (theme) {
		case 'primary':
			className = 'uk-button-primary';
			break;
		case 'secondary':
			className = 'uk-button-secondary';
			break;
		}
		switch (padding) {
		case 'large':
			className += ' uk-padding-large';
			break;
		case 'medium':
			className += ' uk-padding';
			break;
		case 'small':
			className += ' uk-padding-small';
			break;
		}
		return (
			<button
				className={ classnames('uk-button uk-padding-remove-vertical uk-position-relative', className, this.props.className) }
				type={ type }
				onClick={ onClick }
				disabled={ disabled }
			>
				{ text }
				{
					arrow && (
						<FontAwesomeIcon
							icon={ faChevronRight }
							className="uk-position-center-right uk-margin-small-right"
						/>
					)
				}
			</button>
		);
	}
}

Button.propTypes = {
	theme: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	padding: PropTypes.string,
	arrow: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

Button.defaultProps = {
	type: 'button',
	padding: 'large',
	arrow: false,
	className: '',
	disabled: false,
};

export default Button;