import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Modal from 'Components/modal';
import Button from 'Components/button';
import loc from 'Components/languages';
import LoadingOverlay from 'Components/loading-overlay';
import { changePassword } from 'Redux/actions';
import * as cons from 'Redux/constants';
import styles from './ModalPassword.less';

class ModalPassword extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			mismatch: false,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '411px',
				padding: 0,
			},
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const changePasswordInfo = this.props.changePasswordInfo;
		const prevChangePasswordInfo = prevProps.changePasswordInfo;

		if (changePasswordInfo && changePasswordInfo !== prevChangePasswordInfo) {
			switch (changePasswordInfo.type) {
			case cons.CHANGE_PASSWORD.SUCCESS:
				alert(loc.MSGPasswordUpdated);
				this.setState({
					currentPassword: '',
					newPassword: '',
					confirmPassword: '',
				});
				this.props.onToggle();
				break;
			// case cons.CHANGE_PASSWORD.FAILURE:
			// 	if (
			// 		changePasswordInfo.error.detail &&
			// 		changePasswordInfo.error.detail.password &&
			// 		changePasswordInfo.error.detail.password.length > 0
			// 	) {
			// 		alert(changePasswordInfo.error.detail.password.join('\n'));
			// 	} else {
			// 		alert(loc[changePasswordInfo.error.code]);
			// 	}
			// 	break;
			}
		}
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.id] = e.target.value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		const { loginInfo } = this.props;
		const { currentPassword, newPassword } = this.state;
		e.preventDefault();
		const param = {
			payload: {
				username: loginInfo.data.user_id,
				original: currentPassword,
				password: newPassword,
			},
			user: loginInfo.data,
		};
		this.props.changePassword({ ...param });
	}

	render() {
		const { loginInfo, changePasswordInfo, isOpen, onToggle } = this.props;
		const { currentPassword, newPassword, confirmPassword } = this.state;
		const isLoading = !changePasswordInfo || changePasswordInfo.isFetching;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.password }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading }>
					<div className={ styles.desc }>
						{ loc.changePassword }
					</div>

					<div className={ styles.container }>
						<div className={ styles.currentUser }>
							<div className={ classnames('uk-text-primary', styles.label) }>
								{ loc.currentUser }:
							</div>
							<div className={ styles.value }>
								{
									loginInfo && loginInfo.data && (
										<Fragment>
											{ loginInfo.data.user_id }<br />
											{ loginInfo.data.user_name }
										</Fragment>
									)
								}
							</div>
						</div>

						<form className="uk-form-horizontal" onSubmit={ this.onSubmitHandler }>
							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.currentPassword }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<input
										id="currentPassword"
										className="uk-input"
										type="password"
										value={ currentPassword }
										disabled={ isLoading }
										placeholder={ loc.password }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>

							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.newPassword }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<input
										id="newPassword"
										className="uk-input"
										type="password"
										value={ newPassword }
										disabled={ isLoading }
										placeholder={ loc.password }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>

							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.confirmPassword }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<input
										id="confirmPassword"
										className="uk-input"
										type="password"
										value={ confirmPassword }
										disabled={ isLoading }
										placeholder={ loc.password }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>

							<div className={ styles.button }>
								<Button
									theme="primary"
									text={ loc.update }
									type="submit"
								/>
							</div>
						</form>
					</div>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalPassword.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		loginInfo: state.loginInfo,
		changePasswordInfo: state.changePasswordInfo,
	}),
	(dispatch) => ({
		changePassword: para => dispatch(changePassword(para)),
	})
)(ModalPassword);