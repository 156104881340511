exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1Pfa4 {\n  text-align: center;\n  background: #F6F4F0;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  width: 160px;\n  position: relative;\n}\n._1Pfa4.Ak7eJ {\n  background: #e3ddd0;\n}\n._1Pfa4 ._2-21l {\n  padding-top: 10px;\n  padding-bottom: 20px;\n}\n._1Pfa4 .RBi5J {\n  width: 100%;\n  text-align: center;\n  color: #fff;\n  background-color: #ab914c;\n  font-weight: bold;\n  font-size: 36px;\n  margin-bottom: 10px;\n  padding-top: 10px;\n  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n", ""]);

// Exports
exports.locals = {
	"menuBar": "_1Pfa4",
	"uat": "Ak7eJ",
	"logo": "_2-21l",
	"uatLabel": "RBi5J"
};