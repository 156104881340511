import * as cons from './constants';
import * as apis from './apis';
import { action_maker } from '../helpers/helpers';

// Copied from puyi-pos-native
export const postLogin = para => (action_maker({ cons: cons.POST_LOGIN, api: apis.postLogin, ...para }));
export const getCurrentUser = para => (action_maker({ cons: cons.GET_CURRENT_USER, api: apis.getCurrentUser, ...para }));
export const refreshAccessToken = para => (action_maker({ cons: cons.REFRESH_ACCESS_TOKEN, api: apis.refreshAccessToken, ...para }));
export const getSalesman = para => (action_maker({ cons: cons.GET_SALESMAN, api: apis.getSalesman, ...para }));
export const updateSalesOrder = para => (action_maker({ cons: cons.UPDATE_SALESORDER, api: apis.updateSalesOrder, ...para }));
export const confirmSalesOrder = para => (action_maker({ cons: cons.CONFIRM_SALESORDER, api: apis.confirmSalesOrder, ...para }));
export const holdSalesOrder = para => (action_maker({ cons: cons.HOLD_SALESORDER, api: apis.holdSalesOrder, ...para }));
export const retrieveHoldOrder = para => (action_maker({ cons: cons.RETRIEVE_HOLD_SALESORDER, api: apis.retrieveHoldOrder, ...para }));
export const retrieveHoldServiceMemo = para => (action_maker({ cons: cons.RETRIEVE_HOLD_SERVICEMEMO, api: apis.retrieveHoldOrder, ...para }));
export const updateServiceMemo = para => (action_maker({ cons: cons.UPDATE_SERVICEMEMO, api: apis.updateServiceMemo, ...para }));
export const confirmServiceMemo = para => (action_maker({ cons: cons.CONFIRM_SERVICEMEMO, api: apis.confirmServiceMemo, ...para }));
export const holdServiceMemo = para => (action_maker({ cons: cons.HOLD_SERVICEMEMO, api: apis.holdServiceMemo, ...para }));
export const updateShopOrder = para => (action_maker({ cons: cons.UPDATE_SHOPORDER, api: apis.updateShopOrder, ...para }));
export const confirmShopOrder = para => (action_maker({ cons: cons.CONFIRM_SHOPORDER, api: apis.confirmShopOrder, ...para }));
export const holdShopOrder = para => (action_maker({ cons: cons.HOLD_SHOPORDER, api: apis.holdShopOrder, ...para }));
export const retrieveHoldShopOrder = para => (action_maker({ cons: cons.RETRIEVE_HOLD_SHOPORDER, api: apis.retrieveHoldOrder, ...para }));
export const getMember = para => (action_maker({ cons: cons.GET_MEMBER, api: apis.getMember, ...para }));
export const getMemberDetails = para => (action_maker({ cons: cons.GET_MEMBER_DETAILS, api: apis.getMemberDetails, ...para }));
export const getItem = para => (action_maker({ cons: cons.GET_ITEM, api: apis.getItem, ...para }));
export const batchGetItems = para => (action_maker({ cons: cons.BATCH_GET_ITEMS, api: apis.batchGetItems, ...para }));
export const getFavoriteItems = para => (action_maker({ cons: cons.GET_FAVORITE_ITEMS, api: apis.getFavoriteItems, ...para }));
export const setItemToCurrentShop = para => (action_maker({ cons: cons.SET_ITEM_TO_CURRENT_SHOP, api: apis.setItemToCurrentShop, ...para }));
export const unsetItemFromCurrentShop = para => (action_maker({ cons: cons.UNSET_ITEM_FROM_CURRENT_SHOP, api: apis.unsetItemFromCurrentShop, ...para }));
export const getStock = para => (action_maker({ cons: cons.GET_STOCK, api: apis.getItem, ...para }));
export const getServiceItems = para => (action_maker({ cons: cons.GET_SERVICE_ITEMS, api: apis.getServiceItems, ...para }));
export const getPayments = para => (action_maker({ cons: cons.GET_PAYMENTS, api: apis.getPayments, ...para }));
export const getRemark = para => (action_maker({ cons: cons.GET_REMARK, api: apis.getRemark, ...para }));
export const getSalesRemarkTemplates = para => (action_maker({ cons: cons.GET_SALES_REMARK_TEMPLATES, api: apis.getSalesRemarkTemplates, ...para }));
export const getSalesRemarkTemplateDetails = para => (action_maker({ cons: cons.GET_SALES_REMARK_TEMPLATE_DETAILS, api: apis.getSalesRemarkTemplateDetails, ...para }));
export const createSalesRemarkTemplate = para => (action_maker({ cons: cons.CREATE_SALES_REMARK_TEMPLATE, api: apis.createSalesRemarkTemplate, ...para }));
export const updateSalesRemarkTemplate = para => (action_maker({ cons: cons.UPDATE_SALES_REMARK_TEMPLATE, api: apis.updateSalesRemarkTemplate, ...para }));
export const getSalesRemarkTemplateOptions = para => (action_maker({ cons: cons.GET_SALES_REMARK_TEMPLATE_OPTIONS, api: apis.getSalesRemarkTemplateOptions, ...para }));
export const getExchangeRates = para => (action_maker({ cons: cons.GET_EXCHANGE_RATES, api: apis.getExchangeRates, ...para }));
export const getPmsFilters = para => (action_maker({ cons: cons.GET_PMS_FILTERS, api: apis.getPmsFilters, ...para }));
export const getPmsFilterOptions = para => (action_maker({ cons: cons.GET_PMS_FILTER_OPTIONS, api: apis.getPmsFilterOptions, ...para }));
export const getOpmsFilters = para => (action_maker({ cons: cons.GET_OPMS_FILTERS, api: apis.getOpmsFilters, ...para }));
export const getOpmsFilterOptions = para => (action_maker({ cons: cons.GET_OPMS_FILTER_OPTIONS, api: apis.getOpmsFilterOptions, ...para }));
export const getLotItem = para => (action_maker({ cons: cons.GET_LOT_ITEM, api: apis.getLotItem, ...para }));
export const getLotNo = para => (action_maker({ cons: cons.GET_LOTNO, api: apis.getLotNo, ...para }));
export const getTransactions = para => (action_maker({ cons: cons.GET_TRANSACTIONS, api: apis.getTransactions, ...para }));
export const convertQtoR = para => (action_maker({ cons: cons.CONVERT_Q_To_R, api: apis.convertQtoR, ...para }));
export const convertQtoRServiceMemo = para => (action_maker({ cons: cons.CONVERT_Q_To_R_SERVICEMEMO, api: apis.convertQtoR, ...para }));
export const depositRefund = para => (action_maker({ cons: cons.DEPOSIT_REFUND, api: apis.depositRefund, ...para }));
export const depositRefundServiceMemo = para => (action_maker({ cons: cons.DEPOSIT_REFUND_SERVICEMEMO, api: apis.depositRefund, ...para }));
export const voidOrder = para => (action_maker({ cons: cons.VOID_ORDER, api: apis.voidOrder, ...para }));
export const postApproval = para => (action_maker({ cons: cons.POST_APPROVAL, api: apis.postApproval, ...para }));
export const getReturnItem = para => (action_maker({ cons: cons.GET_RETURN_ITEM, api: apis.getReturnItem, ...para }));
export const changePassword = para => (action_maker({ cons: cons.CHANGE_PASSWORD, api: apis.changePassword, ...para }));
export const previewOrder = para => (action_maker({ cons: cons.PREVIEW_ORDER, api: apis.previewOrder, ...para }));
export const viewOrder = para => (action_maker({ cons: cons.VIEW_ORDER, api: apis.viewOrder, ...para }));
export const printOrder = para => (action_maker({ cons: cons.PRINT_ORDER, api: apis.printOrder, ...para }));
export const getTransferInOrder = para => (action_maker({ cons: cons.GET_TRANSFER_IN_ORDER, api: apis.getTransferInOrder, ...para }));
export const getTransferOutOrder = para => (action_maker({ cons: cons.GET_TRANSFER_OUT_ORDER, api: apis.getTransferOutOrder, ...para }));
export const getTransferDetails = para => (action_maker({ cons: cons.GET_TRANSFER_DETAILS, api: apis.getTransferDetails, ...para }));
export const printTransferOrder = para => (action_maker({ cons: cons.PRINT_TRANSFER_ORDER, api: apis.printTransferOrder, ...para }));
export const getWarehouse = para => (action_maker({ cons: cons.GET_WAREHOUSE, api: apis.getWarehouse, ...para }));
export const getCurrentWarehouse = para => (action_maker({ cons: cons.GET_CURRENT_WAREHOUSE, api: apis.getCurrentWarehouse, ...para }));
export const getWarehouseByFilter = para => (action_maker({ cons: cons.GET_WAREHOUSE_BY_FILTER, api: apis.getWarehouseByFilter, ...para }));
export const setUserCurrentShop = para => (action_maker({ cons: cons.SET_USER_CURRENTSHOP, api: apis.setUserCurrentShop, ...para }));
export const unsetUserCurrentShop = para => (action_maker({ cons: cons.UNSET_USER_CURRENTSHOP, api: apis.unsetUserCurrentShop, ...para }));
export const setFavoriteMember = para => (action_maker({ cons: cons.SET_FAVORITE_MEMBER, api: apis.setFavoriteMember, ...para }));
export const unsetFavoriteMember = para => (action_maker({ cons: cons.UNSET_FAVORITE_MEMBER, api: apis.unsetFavoriteMember, ...para }));
export const confirmTransferInOrder = para => (action_maker({ cons: cons.CONFIRM_TRANSFER_IN, api: apis.confirmTransferInOrder, ...para }));
export const createTransferOutOrder = para => (action_maker({ cons: cons.CREATE_TRANSFER_OUT, api: apis.createTransferOutOrder, ...para }));
export const confirmCashMovement = para => (action_maker({ cons: cons.CONFIRM_CASHMOVEMENT, api: apis.confirmCashMovement, ...para }));
export const getCashMovementRecords = para => (action_maker({ cons: cons.GET_CASHMOVEMENT_RECORDS, api: apis.getCashMovementRecords, ...para }));
export const getCashMovementDetails = para => (action_maker({ cons: cons.GET_CASHMOVEMENT_DETAILS, api: apis.getCashMovementDetails, ...para }));
export const changeWarehouse = para => (action_maker({ cons: cons.CHANGE_WAREHOUSE, api: apis.changeWarehouse, ...para }));
export const findGiftCouponByCamera = para => (action_maker({ cons: cons.FIND_GIFT_COUPON_BY_CAMERA, api: apis.findGiftCouponByCamera, ...para }));
export const findGiftCouponByInput = para => (action_maker({ cons: cons.FIND_GIFT_COUPON_BY_INPUT, api: apis.findGiftCouponByInput, ...para }));
export const findCashCouponByInput = para => (action_maker({ cons: cons.FIND_CASH_COUPON_BY_INPUT, api: apis.findCashCouponByInput, ...para }));
export const getReports = para => (action_maker({ cons: cons.GET_REPORTS, api: apis.getReports, ...para }));
export const getReportDetails = para => (action_maker({ cons: cons.GET_REPORT_DETAILS, api: apis.getReportDetails, ...para }));
export const getReportDetailsAsync = para => (action_maker({ cons: cons.GET_REPORT_DETAILS_ASYNC, api: apis.getReportDetailsAsync, ...para }));
export const getReportFilters = para => (action_maker({ cons: cons.GET_REPORT_FILTERS, api: apis.getReportFilters, ...para }));
export const getReportOptions = para => (action_maker({ cons: cons.GET_REPORT_OPTIONS, api: apis.getReportOptions, ...para }));
export const getReportExport = para => (action_maker({ cons: cons.GET_REPORT_EXPORT, api: apis.getReportExport, ...para }));
export const getRftInfo = para => (action_maker({ cons: cons.GET_RFT_INFO, api: apis.getRftInfo, ...para }));
export const getRftInfoDetails = para => (action_maker({ cons: cons.GET_RFT_INFO_DETAILS, api: apis.getRftInfoDetails, ...para }));
export const getSpecialOptions = para => (action_maker({ cons: cons.GET_SPECIAL_OPTIONS, api: apis.getSpecialOptions, ...para }));

// clear cache
export const postLogout = para => (action_maker({ cons: cons.POST_LOGOUT, api: apis.postLogout, ...para }));
export const clearCacheSalesOrder = para => (action_maker({ cons: cons.CLEAR_CACHE_SALESORDER, api: apis.clearCacheSalesOrder, ...para }));
export const clearCacheServiceMemo = para => (action_maker({ cons: cons.CLEAR_CACHE_SERVICEMEMO, api: apis.clearCacheServiceMemo, ...para }));
export const clearCacheShopOrder = para => (action_maker({ cons: cons.CLEAR_CACHE_SHOPORDER, api: apis.clearCacheShopOrder, ...para }));