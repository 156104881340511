import React, { PureComponent, Fragment, lazy, Suspense } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import ReactGA from 'react-ga4';
import MenuBar from 'Containers/MenuBar';
import ModalError from 'Containers/ModalError';
import Spinner from 'Components/spinner';

import { connect } from 'react-redux';
import { getCurrentUser, refreshAccessToken } from 'Redux/actions';
import styles from './index.less';

const fullMenu = lazy(() => import('./full-menu'));
const salesOrder = lazy(() => import('./sales-order'));
const serviceMemo = lazy(() => import('./service-memo'));
const shopOrder = lazy(() => import('./shop-order'));
const stockCheck = lazy(() => import('./stock-check'));
const stockTransfer = lazy(() => import('./stock-transfer'));
const order = lazy(() => import('./order'));
const cashMovement = lazy(() => import('./cash-movement'));
const report = lazy(() => import('./report'));
const remarkTemplate = lazy(() => import('./remark-template'));
const error = lazy(() => import('./error'));

class MainApp extends PureComponent {
	constructor() {
		super();
		this.state = {
			isRefreshing: false,
		};
		this.refreshAccessToken = this.refreshAccessToken.bind(this);
	}

	componentDidMount() {
		this.props.getCurrentUser();
		this.refreshAccessToken();
		this.interval = setInterval(this.refreshAccessToken, 30000); // Check every 30 seconds
	}

	componentDidUpdate(prevProps) {
		if (process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
			const loginInfo = this.props.loginInfo;
			const currentInfo = this.props.currentInfo;
			const prevCurrentInfo = prevProps.currentInfo;
			if (loginInfo && loginInfo.data && currentInfo && currentInfo.data && currentInfo.data.user_id && currentInfo !== prevCurrentInfo) {
				ReactGA.set({
					user_properties: {
						warehouse: loginInfo.data.warehouse_desc.trim(),
						user_id: currentInfo.data.user_id,
					},
				});
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	refreshAccessToken() {
		const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
		const timestamp = (new Date()).getTime() / 1000;
		if (user) {
			if (timestamp - user.timestamp > user.expires_in - 60) {
				// Expiring in 1 minute
				const { isRefreshing } = this.state;
				if (!isRefreshing) {
					this.setState({
						isRefreshing: true,
					});
					this.props.refreshAccessToken({ payload: { apiData: { username: user.user_id } } });
				}
			} else {
				const { isRefreshing } = this.state;
				if (isRefreshing) {
					this.setState({
						isRefreshing: false,
					});
					location.reload();
				}
			}
		}
	}

	render() {
		const { match, history } = this.props;
		return (
			<Fragment>
				<div className="uk-grid" data-uk-height-viewport>
					<MenuBar />
					<main id="workspace" className={ classnames('uk-position-relative', 'uk-width-expand', 'uk-padding-small', styles.main) }>
						<Suspense fallback={ <Spinner /> }>
							<Switch>
								<Route path={ `${match.url}/home` } component={ fullMenu } />
								<Route path={ `${match.url}/sales-order` } component={ salesOrder } />
								<Route path={ `${match.url}/service-memo` } component={ serviceMemo } />
								<Route path={ `${match.url}/shop-order` } component={ shopOrder } />
								<Route path={ `${match.url}/stock-check` } component={ stockCheck } />
								<Route path={ `${match.url}/stock-transfer` } component={ stockTransfer } />
								<Route path={ `${match.url}/order` } component={ order } />
								<Route path={ `${match.url}/cash-movement` } component={ cashMovement } />
								<Route path={ `${match.url}/report` } component={ report } />
								<Route path={ `${match.url}/remark-template` } component={ remarkTemplate } />
								<Route component={ error } />
								<Redirect to="/error" />
							</Switch>
						</Suspense>
						<div className={ classnames('uk-text-small', 'uk-position-absolute', 'uk-position-bottom-left', styles.copyright) }>
							&copy; 2019-{ new Date().getFullYear() } PUYI GROUP LIMITED (HONG KONG)
						</div>
					</main>
				</div>
				<ModalError history={ history } />
			</Fragment>
		);
	}
}

export default withRouter(
	connect(
		(state) => ({
			loginInfo: state.loginInfo,
			currentInfo: state.currentInfo,
		}),
		(dispatch) => ({
			getCurrentUser: () => dispatch(getCurrentUser()),
			refreshAccessToken: para => dispatch(refreshAccessToken(para)),
		})
	)(MainApp)
);